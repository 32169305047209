<template>
  <div class="tw-relative" :class="`tw-h-auto tw-w-full`">
    <video
      :src="src"
      :muted="false"
      :autoplay="false"
      :controls="false"
      :loop="true"
      :width="width"
      :height="height"
      :preload="preload"
      :playsinline="true"
      @ended="onPlayerEnded"
      @canplay="onPlayerCanplay"
      @play="onPlayerPlay"
      ref="player"
    />

    <div id="player-controls" class="tw-bg-black">
      <button
        @click="togglePlay()"
        class="tw-bg-transparent tw-flex tw-inset-0 tw-absolute tw-justify-center tw-items-center"
      >
        <img v-if="!playing" :src="PlayButton" alt="Play" :class="!playing ? 'tw-flex' : 'tw-hidden'" />
        <img v-if="playing" :src="PauseButton" alt="Pause" :class="playing ? 'tw-hidden' : 'tw-flex'" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'

const PlayButton = new URL('/src/assets/img/studio-plus/icons/PlayIcon.svg', import.meta.url)
const PauseButton = new URL('/src/assets/img/studio-plus/icons/PauseIcon.svg', import.meta.url)

import appAnalytics from '@/utils/tracking'

import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  id: { type: String, required: true },
  src: { type: String, required: true },
  controls: { type: Boolean, required: false, default: false },
  loop: { type: Boolean, required: false, default: true },
  width: { type: Number, required: false, default: 500 },
  height: { type: Number, required: false, default: 281 },
  autoplay: { type: Boolean, required: false, default: false },
  muted: { type: Boolean, required: false, default: false },
  poster: { type: String, required: false },
  preload: { type: String, required: false, default: 'auto' },
})
const playing = ref(false)
const playingTime = ref(0)
const player = ref(null)

const play = () => {
  if (props.id === 0) {
    appAnalytics.track(appAnalytics.events.SP_CLICK_HC_VIDEO, {
      accommodationId: selectedAccommodationId.value,
    })
  } else if (props.id === 1) {
    appAnalytics.track(appAnalytics.events.SP_CLICK_RI_VIDEO, {
      accommodationId: selectedAccommodationId.value,
    })
  } else if (props.id === 2) {
    appAnalytics.track(appAnalytics.events.SP_CLICK_VP_VIDEO, {
      accommodationId: selectedAccommodationId.value,
    })
  }
  player.value.play()
  setPlaying(true)
}

watch(
  () => props.data,
  newData => {
    if (props.id !== newData.id) {
      playingTime.value = 0
    }
  }
)

const pause = () => {
  player.value.pause()

  setPlaying(false)
}

const togglePlay = () => {
  if (playing.value) {
    pause()
  } else {
    play()
  }
}

const setPlaying = state => {
  playing.value = state
}

const onPlayerEnded = () => {
  setPlaying(true)
}

const onPlayerCanplay = () => {
  if (playingTime.value === 0) {
    setPlaying(false)
  } else {
    setPlaying(true)
  }
  playingTime.value = playingTime.value + 1
}
</script>
<style scoped>
video:hover ~ #player-controls {
  display: flex;
}
</style>
