<template>
  <BaseCard no-padding class="tw-w-full">
    <div class="tw-flex tw-h-[225px] sm:tw-h-[220px] tw-bg-decorative-pink tw-rounded-t-lg">
      <img
        class="tw-flex tw-items-center tw-justify-center tw-m-auto tw-max-w-[220px]"
        :src="notMobileImg"
        alt="Not Available On Mobile"
      />
    </div>
    <div class="tw-py-10 tw-px-4 sm:tw-py-8">
      <h3 class="tw-pb-6 tw-text-2xl tw-font-bold">{{ $t('rc_error_message_mobile_header') }}</h3>
      <p class="tw-pb-6">{{ $t('rc_error_message_mobile_text') }}</p>
    </div>
  </BaseCard>
</template>
<script setup>
import BaseCard from '@/components/dashboard/BaseCard.vue'

const notMobileImg = new URL('/src/assets/img/rate-connect/mobile_not_available.svg', import.meta.url)
</script>
<style scoped>
.card {
  background: rgb(255, 251, 253);
  background: linear-gradient(
    0deg,
    rgba(255, 251, 253, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 157, 222, 1) 50%,
    rgba(255, 157, 222, 1) 100%
  );
}
</style>
