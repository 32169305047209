<template>
  <section class="tw-py-16">
    <div
      class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-w-full tw-flex-col"
    >
      <h1
        class="tw-text-4xl tw-font-bold tw-text-center tw-text-blue-700 tw-mb-10 md:tw-w-2/3 lg:tw-w-full md:tw-mx-auto"
      >
        {{ $t('tbs_mp_header_premium') }}
      </h1>
      <div class="tw-flex tw-w-full tw-flex-col md:tw-flex-row">
        <div
          v-for="(product, index) in products"
          :key="product.name"
          class="md:tw-w-1/2 tw-flex tw-flex-col"
          :class="{ 'tw-mb-12 md:tw-mb-0': index === 0 }"
        >
          <div
            :class="{ 'md:tw-border-r md:tw-border-gray-700': index === 0 }"
            class="lg:odd:tw-pl-[52px] md:tw-px-8 tw-box-border tw-grow"
          >
            <div class="tw-flex tw-justify-center sm:tw-justify-start">
              <img :src="product.logo" :alt="product.name" height="28" class="tw-mb-11" />
            </div>
            <ul>
              <li
                v-for="(benefit, index) in product.benefits"
                :key="index"
                class="tw-mb-6 last:tw-mb-0 tw-flex tw-items-start"
              >
                <CheckMarkInCircle height="19px" width="19px" class="tw-inline tw-mr-2 tw-stroke-blue-700 tw-mt-1" />
                {{ $t(benefit) }}
              </li>
            </ul>
          </div>
          <GhostButton
            class="lg:tw-mx-[72px] md:tw-mx-8 tw-mt-11 md:tw-mb-0 tw-w-fit tw-m-auto"
            @click="redirectToLanding(product.redirect)"
            >{{ $t('tbs_mp_learn_more_cta') }}</GhostButton
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'

const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)
const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)

const products = [
  {
    logo: RateConnectLogo,
    redirect: '/home/getstarted/rate-connect',
    name: 'Rate Connect',
    benefits: ['tbs_mp_rc_benefit_1', 'tbs_mp_rc_benefit_2', 'tbs_mp_rc_benefit_3', 'tbs_mp_rc_benefit_4'],
  },
  {
    logo: StudioPlusLogo,
    redirect: '/home/getstarted/studio-plus',
    name: 'Business Studio+',
    benefits: ['tbs_mp_studo_plus_benefit_2', 'tbs_mp_studo_plus_benefit_3', 'tbs_mp_studo_plus_benefit_4'],
  },
]

const redirectToLanding = url => {
  return window.open(url, '_blank')
}
</script>
