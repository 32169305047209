<template>
  <BaseCard>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-800 tw-mb-8">
      <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
        <div>
          <p class="tw-text-xl tw-font-bold">{{ $t('form.info.heading_language_currency') }}</p>
        </div>
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-my-4">
          <div class="tw-w-full lg:tw-w-1/2 tw-mr-4">
            <p class="tw-font-bold">{{ $t('form.labels.language') }}</p>
            <LanguageSelector size="big" :with-image="false" version="light" />
          </div>
          <div class="tw-w-full lg:tw-w-1/2">
            <p class="tw-font-bold">{{ $t('form.labels.currency') }}</p>
            <div v-if="isOrganisationsCurrencyLoading && isLoading" class="tw-w-full">
              <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-12" aria-hidden="true"></div>
            </div>
            <CurrencySelector
              v-else
              size="big"
              :with-image="false"
              version="light"
              :source="'settings'"
              :isDefaultMethod="false"
              :isFullName="true"
              :isLanding="false"
            />
            <div class="tw-text-sm">
              <span v-if="isCurrencyEditable">{{ $t('form.info.description.currency1') }}</span>
              <span v-else>{{ $t('form.info.description.currency2') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import LanguageSelector from '@/components/locale/LanguageSelector.vue'
import CurrencySelector from '@/components/locale/CurrencySelector.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import { useGetUserCurrency } from '@/components/locale/queries'
import { useZuoraAccount } from '@/components/payment/queries'

const store = useStore()
const token = computed(() => store.state.session?.studioToken)
const isGuest = computed(() => false)

const { isOrganisationsCurrencyLoading } = useGetUserCurrency({ token, isGuest })
const { isLoading } = useZuoraAccount(token)

const isCurrencyEditable = computed(() => store.state.isCurrencyEditable)
</script>
