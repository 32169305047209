<template>
  <div class="tw-pb-6">
    <div
      v-for="paymentMethod in availablePaymentMethods"
      :key="paymentMethod.key"
      class="tw-flex tw-flex-col"
      :class="availablePaymentMethods.length > 1 ? 'tw-py-3' : ''"
    >
      <div class="tw-flex tw-items-center tw-w-full">
        <input
          type="radio"
          name="paymentMethod"
          :value="paymentMethod?.pageType"
          :id="paymentMethod?.pageId"
          :checked="selectedPaymentMethod === paymentMethod?.pageType"
          @change="onPaymentMethodTypeChange(paymentMethod)"
          class="tw-cursor-pointer"
        />
        <label
          :for="paymentMethod?.pageId"
          class="tw-cursor-pointer tw-m-0 tw-ml-2 tw-grow tw-flex tw-items-center tw-flex-wrap"
          ><span class="tw-grow">{{ $t(`form.paymentMethods.${paymentMethod?.pageType}`) }}</span>
          <div v-if="paymentMethod?.pageType === 'CREDIT_CARD'" class="tw-w-[140px]">
            <img
              :src="visaLogo"
              alt="Visa logo"
              height="24"
              width="38"
              class="tw-shadow-[0_0_1px_0_rgba(41,52,58,0.75)] tw-mr-2 tw-inline"
            />
            <img
              :src="mastercardLogo"
              alt="Mastercard logo"
              height="24"
              width="38"
              class="tw-shadow-[0_0_1px_0_rgba(41,52,58,0.75)] tw-mr-2 tw-inline"
            />
            <img
              :src="americanexpressLogo"
              alt="American Express logo"
              height="24"
              width="38"
              class="tw-shadow-[0_0_1px_0_rgba(41,52,58,0.75)] tw-mr-2 tw-inline"
            />
          </div>
        </label>
      </div>
      <div>
        <div
          v-if="fetchingPaymentMethods && selectedPaymentItem?.pageType === paymentMethod.pageType"
          class="tw-overlay-white-75 tw-h-10 tw-mb-5 tw-w-full"
        >
          <LaunchpadLoader class="tw-h-full tw-flex tw-justify-center tw-items-center" />
        </div>

        <div
          class="tw-px-4 tw-pt-4"
          id="zuora_payment"
          v-if="selectedPaymentItem?.pageType === paymentMethod?.pageType"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useErrorMetrics } from '@/components/payment/queries/index.js'

import { useI18n } from 'vue-i18n'
import { sepaCountries } from '@/constants/sepaCountries.js'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import zuoraMetricConstants from '@/constants/zuoraMetricConstants'

const { t } = useI18n()
const props = defineProps({
  accommodationId: Number,
  currency: {
    type: String,
    default: 'EUR',
  },
  selectedPaymentMethod: {
    type: String,
    default: null,
  },
  availablePaymentMethods: {
    type: Array,
    default: () => [],
  },
  fetchingPaymentMethods: {
    type: Boolean,
    default: false,
  },
  productName: String,
})

const emit = defineEmits([
  'onChange',
  'onZuoraCallbackSuccess',
  'onZuoraCallbackError',
  'onZuoraClientValidationError',
  'setError',
])

const store = useStore()
const onZuoraCallbackError = withRerender => emit('onZuoraCallbackError', withRerender)

const visaLogo = new URL('/src/assets/img/settings-payment/visa.png', import.meta.url)
const mastercardLogo = new URL('/src/assets/img/settings-payment/mastercard.png', import.meta.url)
const americanexpressLogo = new URL('/src/assets/img/settings-payment/americanexpress.png', import.meta.url)

const { sendErrorMetrics } = useErrorMetrics()
const language = computed(() => store.state.locale?.language)
const countryWhiteList = computed(() => sepaCountries?.map(country => country.country_code))

// Hardcoded currency for the pilot. Users are going to be based in EU countries.
const currency = props.currency ?? 'EUR'

const selectedPaymentItem = ref(null)

watch(
  () => props.availablePaymentMethods,
  (availablePaymentMethods, prevAvailablePaymentMethods) => {
    if (prevAvailablePaymentMethods !== availablePaymentMethods) {
      selectedPaymentItem.value = props.availablePaymentMethods.find(
        item => item?.pageType === selectedPaymentItem.value?.pageType
      )

      nextTick(() => {
        renderZuoraHpp()
      })
    }
  }
)

const onPaymentMethodTypeChange = paymentMethod => {
  selectedPaymentItem.value = paymentMethod
  emit('onChange', paymentMethod?.pageType)
  nextTick(() => {
    renderZuoraHpp()
  })
}
// map Zuora error codes to translation keys
const parseErrorFromCode = code => {
  switch (code) {
    case '001':
      return 'required_field_empty'
    case '002':
    case '003':
      return 'payment_invalid_card_number'
    case '004':
      return 'payment_cvc_declined'
    default:
      return 'payment_declined_generic'
  }
}

// map Zuora error messages to translation keys
const parseErrorFromMessage = (message = 'unknown') => {
  if (message.startsWith('[BusinessValidationError]')) {
    return 'payment_expired_card'
  } else if (message.startsWith('[GatewayTransactionError]')) {
    const gatewayTransactionErrorMap = {
      'declined.Refused - Expired Card': 'payment_expired_card',
      'declined.103 - CVC is not the right length': 'payment_invalid_cvc_length',
      'declined.Refused - CVC Declined': 'payment_invalid_cvc_length',
      'declined.Refused - Refused': 'payment_restricted_card',
      'declined.Refused - Transaction Not Permitted': 'payment_restricted_card',
      'declined.Refused - Restricted Card': 'payment_restricted_card',
      'declined.Refused - Invalid Card Number': 'payment_invalid_card_number',
      'declined.112 - This bank country is not supported': 'payment_unsupported_bank_country',
      'declined.701': 'payment_inconsistent_iban',
      'declined.111 - Invalid BankCountryCode specified': 'payment_inconsistent_iban',
      'declined.161 - Invalid iban': 'payment_inconsistent_iban',
      'declined.203 - Invalid Bank account holder name': 'invalid_account_holder',
    }

    for (const [key, value] of Object.entries(gatewayTransactionErrorMap)) {
      if (message.includes(key)) {
        return value
      }
    }
  }
  return 'payment_declined_generic'
}

/**
 * Identifies the type of error depending on what zuora sends after the hpp has been submitted
 * and handles what to send to the metrics collector service
 * @param {String} originalMessage
 * @param {String} mappedMessage
 * @param {String} validationCode
 */
const handleErrorMetric = (originalMessage, mappedMessage, validationCode) => {
  const errorCodes = /000|400|401|403|404|422|500/
  const isClientValidationCode = validationCode !== 'unknown' || originalMessage.includes('BusinessValidationError')
  const isGatewayTransactionError = originalMessage.includes('GatewayTransactionError')
  const isConfigurationError = isGatewayTransactionError && errorCodes.test(originalMessage) && !isClientValidationCode

  // Payment errors from customer input errors, without alert
  const metricPayload = {
    payload: {
      original_error: originalMessage,
      mapped_error: mappedMessage,
    },
    code: zuoraMetricConstants.metricErrorCodes.paymentError,
    message: `Error while processing payment - ${t(mappedMessage)} - ${originalMessage} `,
  }

  if (isConfigurationError || (!isGatewayTransactionError && !isClientValidationCode)) {
    // Register and alert about a potential configuration error in gateway or hpp
    metricPayload.code = zuoraMetricConstants.metricErrorCodes.configurationError
    metricPayload.message = `Zuora HPP configuration error - ${t(mappedMessage)}`
  } else if (isClientValidationCode) {
    // Register payment errors caused by client validation errors, without alert
    metricPayload.code = zuoraMetricConstants.metricErrorCodes.clientSideValidationError
    metricPayload.message = `Zuora HPP client side validation error - ${t(mappedMessage)}`
  }
  sendErrorMetrics({
    itemId: props.accommodationId,
    productName: props.productName,
    code: metricPayload.code,
    message: `${metricPayload.message}`,
  })
}

const renderZuoraHpp = () => {
  const params = {
    style: 'inline',
    submitEnabled: true,
    locale: language.value ? language.value.replace('-', '_') : 'en_US',
    currency: currency,
    token: selectedPaymentItem.value?.token,
    signature: selectedPaymentItem.value?.signature,
    key: selectedPaymentItem.value?.key,
    url: selectedPaymentItem.value?.zuoraHppAppUrl,
    tenantId: selectedPaymentItem.value?.tenantId,
    id: selectedPaymentItem.value?.pageId,
  }

  if (selectedPaymentItem.value?.pageType === 'SEPA') params.countryWhiteList = countryWhiteList.value
  Z.renderWithErrorHandler(params, {}, onZuoraCallback, onZuoraClientErrorCallback)
}

const onZuoraClientErrorCallback = (key, code, message) => {
  let errorMessage = message

  if (['001', '002', '003', '004'].includes(code)) {
    errorMessage = parseErrorFromCode(code)
  } else {
    errorMessage = parseErrorFromMessage(message)
  }

  errorMessage = `checkout_errors.payment.ar-studio-subscription-manager_${errorMessage}`
  emit('setError', errorMessage)

  handleErrorMetric(message, errorMessage, code)
  Z.sendErrorMessageToHpm(key, message)
  emit('onZuoraClientValidationError', errorMessage)
}

const onZuoraCallback = response => {
  if (response.success) {
    emit('setError', false)
    emit('onZuoraCallbackSuccess', response.refId)
  } else {
    if (response.errorCode === 'Attempt_Exceed_Limitation') {
      emit('setError', false)
      onZuoraCallbackError(true)
    } else {
      sendErrorMetrics({
        itemId: props.accommodationId,
        productName: props.productName,
        code: response.errorCode,
        message: response.errorMessage,
      })
      emit('setError', response.errorMessage)
      onZuoraCallbackError(false)
    }
  }
}
</script>
