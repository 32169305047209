<template>
  <div
    v-if="!isLoading"
    class="tw-relative"
    :key="selectedAccommodationId"
    :class="{ 'currency-dropdown': version === 'footer' }"
  >
    <div
      v-if="version === 'footer'"
      class="custom-selected tw-flex tw-justify-end tw-right-0 tw-border-0 tw-max-h-[36px] tw-px-2 tw-py-1 tw-rounded-md tw-text-gray-800"
    >
      <div class="tw-flex tw-flex-row tw-items-center">
        <span class="tw-mr-[25px] tw-text-gray-800 tw-text-sm tw-uppercase">{{ selectedCurrency }}</span>
        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          :icon-color="'#007CC2'"
          class="tw-absolute arrow-dropdown"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </div>
    <BaseSelect
      v-show="selectedCurrency"
      :isDisabled="!isCurrencyEditable || isOrganisationCurrencyFetching"
      :id="'currencies-selector'"
      :class="{
        'currency-selector': version !== 'light',
      }"
      :name="'currencies-selector'"
      display-text-key="displayText"
      :value-key="'code'"
      :options="formattedCurrencies"
      :value="selectedCurrency"
      :version="version"
      :size="size"
      @onChange="updateSelectedCurrency"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, watch, onMounted } from 'vue'

import BaseSelect from '@/components/BaseSelect.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

import { selectedAccommodationId } from '@/layouts/queries/index.js'
import { useGetUserCurrency, useUpdateCurrency } from './queries/index.js'
import { useZuoraAccount } from '@/components/payment/queries'
import { CURRENCIES } from '@/constants/currencies.js'
import { SUPPORTED_CURRENCIES } from '@/constants/supportedCurrencies.js'

import useToastNotifications from '@/components/notifications/useToastNotifications'
import appAnalytics from '@/utils/tracking'

const props = defineProps({
  // defines if the values shown in the dropdown should be only symbol of full currency name
  isFullName: Boolean,
  version: String,
  size: String,
  isLanding: Boolean,
})

const store = useStore()

const { displayNotification } = useToastNotifications()
const { updateCurrency } = useUpdateCurrency()

const userId = computed(() => store.state.session.userId)
const selectedCurrency = computed(() => store.state.currency)
const isCurrencyEditable = computed(() => store.state.isCurrencyEditable)
const token = computed(() => store.state.session?.studioToken)
const isGuest = computed(() => props.isLanding)

// Check if user has currency in Zuora
const { zuoraAccountData, isLoading } = useZuoraAccount(token)
// Returns the currency corresponding by GEO IP if guest user
const { userCurrency, isOrganisationCurrencyFetching } = useGetUserCurrency({ token, isGuest })

// Creates the desired display text for the select drop down (symbol - full currency name)
const formattedCurrencies = computed(() => {
  if (!Array.isArray(SUPPORTED_CURRENCIES)) return []
  return SUPPORTED_CURRENCIES.map(currency => {
    return {
      ...currency,
      displayText: props.isFullName ? `${CURRENCIES[currency.code]} - ${currency.name}` : `${currency.code}`,
    }
  })
})

const updateCurrencyState = newValue => {
  store.commit('SET_CURRENCY', newValue.currency?.code)
  store.commit('SET_CURRENCY_NAME', newValue.currency?.name)
  window.localStorage.setItem('selected_currency', JSON.stringify(newValue.currency))
  store.commit('SET_IS_CURRENCY_EDITABLE', newValue?.editable)
}

const saveUpdatedCurrency = newSelectedCurrency => {
  if (!userId.value) return
  updateCurrency(
    {
      userId: userId.value,
      currency: newSelectedCurrency,
    },
    {
      onSuccess: data => {
        const errors = data?.data?.errors ?? []
        if (errors.length > 0) {
          displayNotification({
            message: 'something_went_wrong',
            isTranslationKey: true,
            type: 'error',
          })
          return
        }
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

const setInitialCurrency = () => {
  const currencyInLocalStorage = window.localStorage.getItem('selected_currency')

  // 1. Currency from Zuora -> not editable
  if (zuoraAccountData.value && zuoraAccountData.value?.billingAndPayment?.currency) {
    const currentCurrency = SUPPORTED_CURRENCIES.find(
      item => item.code === zuoraAccountData.value?.billingAndPayment?.currency
    )
    updateCurrencyState({
      currency: currentCurrency,
      editable: false,
    })
    saveUpdatedCurrency(currentCurrency.code)
    // Currency from local storage -> editable
  } else if (currencyInLocalStorage) {
    let parsedCurrencyInLocalStorage
    try {
      parsedCurrencyInLocalStorage = JSON.parse(currencyInLocalStorage)
    } catch {
      window.localStorage.removeItem('selected_currency')
      updateCurrencyState({ currency: { code: 'EUR', name: 'Euro' }, editable: true })
      saveUpdatedCurrency('EUR')
      return
    }
    updateCurrencyState({ currency: parsedCurrencyInLocalStorage, editable: true })
    saveUpdatedCurrency(parsedCurrencyInLocalStorage.code)
    // Currency from Premium API -> editable
  } else if (userCurrency.value) {
    const currentCurrency = SUPPORTED_CURRENCIES.find(item => item.code === userCurrency.value)
    updateCurrencyState({
      currency: currentCurrency,
      editable: true,
    })
    saveUpdatedCurrency(currentCurrency.code)
    // Default EUR currency -> editable
  } else {
    updateCurrencyState({
      currency: SUPPORTED_CURRENCIES.find(item => item.code === 'EUR'),
      editable: true,
    })
    saveUpdatedCurrency('EUR')
  }
}

watch(
  userCurrency,
  newValue => {
    if (!newValue || newValue === selectedCurrency.value) return
    setInitialCurrency()
  },
  { immediate: true }
)

watch(
  zuoraAccountData,
  newValue => {
    if (newValue === selectedCurrency.value) return
    setInitialCurrency()
  },
  { immediate: true }
)

onMounted(() => {
  if (!isLoading) {
    setInitialCurrency()
  }
})

const updateSelectedCurrency = newSelectedCurrency => {
  if (props.source === 'studio-plus-payment-page') {
    appAnalytics.track(appAnalytics.events.SP_SELECT_CURRENCY_PAYMENT_PAGE)
  }
  if (!newSelectedCurrency) return
  if (isCurrencyEditable.value) {
    const currencyName = SUPPORTED_CURRENCIES?.find(currency => currency.code === newSelectedCurrency)?.name
    updateCurrencyState({
      currency: { code: newSelectedCurrency, name: currencyName },
      editable: true,
    })
  }
  saveUpdatedCurrency(newSelectedCurrency)
}
</script>
<style scoped>
.custom-selected {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.currency-dropdown:hover .custom-selected {
  @apply tw-bg-gray-100;
}

.currency-selector {
  opacity: 0;
}
.custom-selected-border {
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding-right: 30px;
  border-radius: 10px;
}
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
</style>
