<template>
  <div class="tw-flex tw-flex-col tw-mx-auto tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-px-4 tw-my-12">
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-mb-12 tw-text-center">
      <h3 class="tw-text-2xl tw-font-bold tw-mb-2">{{ $t('studio_plus_mp_subheader') }}</h3>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('plus_product_page_features_subtext') }}</p>
    </div>
    <div
      class="tw-flex tw-gap-4 tw-flex-col md:tw-flex-row"
      :class="activeTab !== null ? 'tw-items-start' : 'tw-items-center'"
    >
      <div class="tw-w-full md:tw-w-1/2">
        <div
          v-for="item in products"
          :key="item.id"
          :class="getActiveClass(item)"
          class="tw-mb-2 tw-p-4 tw-flex tw-flex-col tw-rounded-xl tw-gap-x-4 tw-w-full"
        >
          <div class="tw-flex tw-justify-between tw-items-center tw-w-full" @click="openTab(item.id)">
            <div class="tw-flex tw-items-start md:tw-items-center tw-gap-4 tw-pr-2 md:tw-pr-8">
              <div class="tw-min-w-12 tw-mt-1 md:tw-mt-0">
                <img :src="item.icon" />
              </div>
              <div class="tw-w-auto">
                <p class="tw-font-bold tw-text-xl tw-mb-1" :class="{ 'tw-text-gray-500': item.isDisabled }">
                  {{ $t(item.title) }}
                </p>
                <p class="tw-text-sm tw-text-gray-700">{{ $t(item.subtitle) }}</p>
              </div>
            </div>

            <BaseIcon
              width="24"
              height="24"
              iconName="chevron-right"
              :class="arrowCustomClasses(item)"
              v-if="!item.isDisabled"
            >
              <ChevronRight />
            </BaseIcon>
          </div>

          <div
            v-if="activeTab === 0 && item.id === activeTab"
            id="content-0"
            class="tw-px-4 tw-transition-all tw-duration-300 tw-ease-in-out"
          >
            <ul class="tw-list-disc tw-mt-8 tw-text-sm">
              <li v-for="(item, index) in item.list" :key="index" class="tw-mb-2" v-html="$t(item)" />
            </ul>
          </div>
          <div
            v-if="activeTab === 1 && item.id === activeTab"
            id="content-1"
            class="tw-px-4 tw-transition-all tw-duration-300 tw-ease-in-out"
          >
            <ul class="tw-list-disc tw-mt-8 tw-text-sm">
              <li v-for="(item, index) in item.list" :key="index" class="tw-mb-2" v-html="$t(item)" />
            </ul>
          </div>
          <div
            v-if="activeTab === 2 && item.id === activeTab"
            id="content-2"
            class="tw-px-4 tw-transition-all tw-duration-300 tw-ease-in-out"
          >
            <ul class="tw-list-disc tw-mt-8 tw-text-sm">
              <li v-for="(item, index) in item.list" :key="index" class="tw-mb-2" v-html="$t(item)" />
            </ul>
          </div>
        </div>
      </div>
      <div class="tw-hidden md:tw-flex md:tw-w-1/2">
        <img v-if="activeTab === null" :src="CoverImage" alt="Studio Plus Smart Features" />
        <Videoplayer v-else :src="videoUrl" height="400px" width="100%" :id="activeTab" :key="activeTab" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import Videoplayer from '@/components/studioPlus/Videoplayer.vue'

import appAnalytics from '@/utils/tracking'

import { selectedAccommodationId } from '@/layouts/queries'

const HotelConnect = new URL('/src/assets/img/studio-plus/icons/HotelConnect.svg', import.meta.url)
const RateInsights = new URL('/src/assets/img/studio-plus/icons/RateInsights.svg', import.meta.url)
const TravelerTrends = new URL('/src/assets/img/studio-plus/icons/TravelerTrends.svg', import.meta.url)
const MoreFeatures = new URL('/src/assets/img/studio-plus/icons/MoreFeatures.svg', import.meta.url)
const CoverImage = new URL(`@/assets/img/studio-plus/plus-smart-features-cover.svg`, import.meta.url)
const HotelConnectVideo = new URL('@/assets/videos/HotelConnect.mp4', import.meta.url)
const RateInsightsVideo = new URL('@/assets/videos/RateInsights.mp4', import.meta.url)
const TravelerTrendsVideo = new URL('@/assets/videos/VisitorsProfile.mp4', import.meta.url)

const activeTab = ref(null)
const products = [
  {
    title: 'hotel_connect',
    id: 0,
    subtitle: 'plus_product_page_hc_subtext',
    icon: HotelConnect,
    isDisabled: false,
    list: ['plus_product_page_hc_point_1', 'plus_product_page_hc_point_2', 'plus_product_page_hc_point_3'],
  },
  {
    title: 'app.rateInsights.name',
    id: 1,
    subtitle: 'plus_product_page_ri_subtext',
    icon: RateInsights,
    isDisabled: false,
    list: ['plus_product_page_ri_point_1', 'plus_product_page_ri_point_2', 'plus_product_page_ri_point_3'],
  },
  {
    title: 'app.visitorsProfile.name',
    id: 2,
    subtitle: 'plus_product_page_vp_subtext',
    icon: TravelerTrends,
    isDisabled: false,
    list: ['plus_product_page_vp_point_1', 'plus_product_page_vp_point_2', 'plus_product_page_vp_point_3'],
  },
  {
    title: 'plus_product_page_new_feature',
    id: 3,
    subtitle: '',
    icon: MoreFeatures,
    isDisabled: true,
  },
]

const videoUrl = computed(() => {
  if (activeTab.value === 0) return HotelConnectVideo
  if (activeTab.value === 1) return RateInsightsVideo
  if (activeTab.value === 2) return TravelerTrendsVideo
  else return null
})

const openTab = id => {
  if (id !== 3 && id === activeTab.value) {
    if (id === 0) {
      appAnalytics.track(appAnalytics.events.SP_CLICK_HC_TILE, {
        accommodationId: selectedAccommodationId.value,
      })
    } else if (id === 1) {
      appAnalytics.track(appAnalytics.events.SP_CLICK_RI_TILE, {
        accommodationId: selectedAccommodationId.value,
      })
    } else if (id === 2) {
      appAnalytics.track(appAnalytics.events.SP_CLICK_VP_TILE, {
        accommodationId: selectedAccommodationId.value,
      })
    }
  }
  activeTab.value = id === 3 || id === activeTab.value ? null : id
}

const getActiveClass = item => {
  const classes = []
  if (!item.isDisabled) {
    classes.push('hover:tw-cursor-pointer')
  }
  if (activeTab.value === item.id) {
    if (activeTab.value === 0) {
      classes.push('tw-bg-orange-100')
    } else if (activeTab.value === 1) {
      classes.push('tw-bg-red-100')
    } else if (activeTab.value === 2) {
      classes.push('tw-bg-blue-100')
    }
  }
  return classes.join(' ')
}

const arrowCustomClasses = item => {
  const classes = []
  if (item.isDisabled) {
    classes.push('tw-fill-gray-500')
  }
  if (item.id === activeTab.value) {
    classes.push('tw--rotate-90')
  } else {
    classes.push('tw-rotate-90')
  }
  return classes.join(' ')
}
</script>
