<template>
  <div class="tw-px-4">
    <div class="tw-flex tw-justify-start tw-mb-6">
      <router-link
        class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-700 tw-flex tw-items-end"
        :to="isReactivate ? { name: reactivationBackPath } : { name: 'studio-plus-compare' }"
      >
        <BaseIcon icon-name="chevron-right" class="tw-rotate-180">
          <ChevronRight />
        </BaseIcon>
        <span>{{ $t('setup_back') }}</span>
      </router-link>
    </div>
    <h2 class="tw-text-2xl tw-font-bold tw-mb-6">
      {{
        isReactivate
          ? $t('business_studio_plus_payment_details_header')
          : $t('business_studio_plus_checkout_subscription_header')
      }}
    </h2>
    <div v-if="isLoadingRequiredData">
      <div class="tw-animate-pulse tw-w-full tw-rounded-lg tw-bg-gray-300 tw-h-[300px] tw-mb-4 tw-mt-8"></div>
      <div class="tw-animate-pulse tw-w-full tw-rounded-lg tw-bg-gray-300 tw-h-[300px] tw-mb-4 tw-mt-8"></div>
    </div>
    <div v-else-if="isRequiredDataError" class="tw-mb-4 tw-mt-8">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <div v-else class="sp-payment-grid">
      <div class="sp-payment-accommodation">
        <PlusCurrencySelector v-if="!isReactivate && !zuoraAccountData?.billingAndPayment?.currency" />
        <SubscriptionSelectedAccommodation v-if="isReactivate" :accommodationId="reactivateAccommodation" />
      </div>
      <div class="sp-payment-form">
        <PaymentForm
          :class="isReactivate ? 'tw-my-6' : 'tw-mb-6'"
          :key="selectedAccommodationId"
          ref="paymentFormRef"
          :currency="currency"
          :accommodationId="isReactivate ? reactivateAccommodation : selectedAccommodationId"
          :productName="zuoraMetricConstants.productName.plus"
          @onFormValidationSuccess="onFormValidationSuccess"
          @onFormValidationError="onFormValidationError"
          @onZuoraHostedPageLoadError="onFormValidationError"
        />
      </div>
      <div class="sp-payment-footer tw-mb-6">
        <div class="sticky">
          <h3 class="tw-font-bold tw-mb-1">{{ $t('business_studio_plus_checkout_summary') }}</h3>
          <BaseCard noPadding class="tw-px-4 tw-py-6">
            <SubscriptionPriceSummary :price="studioPlusPrice" :loading="isFetching || isLoading" />
            <div class="tw-flex tw-gap-3 tw-items-center tw-mb-6">
              <input
                id="studio-plus-terms"
                type="checkbox"
                class="hover:tw-cursor-pointer tw-h-4 tw-w-4"
                :value="termsAccepted"
                @change="e => (termsAccepted = e.target.checked)"
              />
              <label
                for="studio-plus-terms"
                class="tw-grow tw-text-sm tw-leading-4 tw-mb-0 terms"
                v-html="$t('business_studio_plus_terms_and_conditions_checkbox')"
              />
            </div>
            <MainButton class="tw-w-full tw-mb-6" :disabled="!paymentEnabled" @click="onStartFreeTrial">{{
              isReactivate ? $t('business_studio_plus_reactivation_checkout') : $t('check_out_page_plus_cta')
            }}</MainButton>
            <p class="tw-flex tw-gap-3 tw-mb-4">
              <BaseIcon :height="24" :width="24" icon-name="lock"> <Lock /> </BaseIcon
              >{{ $t('checkoutBasket.detailsEntry.secureConnection.recurringFee') }}
            </p>
            <p class="tw-mb-4 tw-flex tw-gap-3">
              <BaseIcon :height="24" :width="24" icon-name="information-outline" class="tw-fill-none">
                <CircleInfo />
              </BaseIcon>
              {{ $t('checkout_subscription_tax_notification') }}
            </p>
            <p class="tw-mb-4 tw-flex tw-gap-3" v-if="isReactivate">
              <BaseIcon :height="24" :width="24" icon-name="update">
                <Update />
              </BaseIcon>
              {{ $t('business_studio_plus_reactivation_date', { ReactivationDate: reactivationDate }) }}
            </p>
            <p class="tw-mb-4 tw-flex tw-gap-3">
              <BaseIcon :height="24" :width="24" icon-name="update">
                <Update />
              </BaseIcon>
              {{ $t('business_studio_plus_subscription_renewal_info_annual') }}
            </p>
            <p class="tw-flex tw-gap-3">
              <BaseIcon :height="24" :width="24" icon-name="gear-wheel">
                <GearWheel />
              </BaseIcon>
              {{ $t('business_studio_plus_checkout_info_text') }}
            </p>
          </BaseCard>
        </div>
      </div>
    </div>
    <FullScreenLoader v-show="isSubmittingNewSubscription || isFetchingRequiredData" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import GearWheel from '@/components/icons/GearWheel.vue'
import Lock from '@/components/icons/Lock.vue'
import CircleInfo from '@/components/icons/CircleInfo.vue'
import Update from '@/components/icons/Update.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import PaymentForm from '@/components/payment/PaymentForm.vue'
import SubscriptionPriceSummary from '@/components/studioPlus/SubscriptionPriceSummary.vue'
import PlusCurrencySelector from '@/components/studioPlus/PlusCurrencySelector.vue'
import SubscriptionSelectedAccommodation from '@/components/studioPlus/SubscriptionSelectedAccommodation.vue'
import {
  useAvailablePaymentMethods,
  useExistingPaymentMethods,
  useZuoraAccount,
  useStudioPlusNewSubscriptionMutation,
  useStudioPlusPrice,
} from '@/components/payment/queries'
import { selectedAccommodationId, useUserDetails, useAccommodationsByOrgId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import zuoraMetricConstants from '@/constants/zuoraMetricConstants'

const store = useStore()
const router = useRouter()
const route = useRoute()
const queryClient = useQueryClient()

const currency = computed(() => store.state.currency)
const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const reactivationSubscriptionType = computed(() => store.state.studioPlus.reactivationSubscriptionType)
const isReactivate = reactivationAccommodationId.value !== null && reactivationSubscriptionType.value !== null
const reactivateAccommodation = reactivationAccommodationId.value
const reactivationBackPath = computed(() => {
  if (isReactivate && route.query.source === 'home') return 'home'
  if (isReactivate && route.query.source === 'settings') return 'settings-subscriptions'
  if (isReactivate && route.query.source === 'subscription-view') return 'settings-subscriptions'
  else return ''
})

const currentAccommodationId = isReactivate ? reactivateAccommodation : selectedAccommodationId.value
const userId = computed(() => store.state.session.userId)
const token = computed(() => store.state.session?.studioToken)
const { userDetails } = useUserDetails(userId)

const { isError: isAvailablePaymentMethodsError } = useAvailablePaymentMethods(selectedAccommodationId, currency)
const {
  existingPaymentMethodsDataLoading: isExistingPaymentMethodsLoading,
  isFetching: isExistingPaymentMethodsFetching,
  isError: isExistingPaymentMethodsError,
} = useExistingPaymentMethods(selectedAccommodationId)
const {
  zuoraAccountData,
  isLoading: isZuoraAccountLoading,
  isFetching: isZuoraAccountFetching,
  isError: isZuoraAccountError,
} = useZuoraAccount(token)
const { sendStudioPlusNewSubscription } = useStudioPlusNewSubscriptionMutation()

const reactivationDate = computed(() => {
  return new Date().toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})
const isLoadingRequiredData = computed(() => isExistingPaymentMethodsLoading.value || isZuoraAccountLoading.value)
const isFetchingRequiredData = computed(() => isExistingPaymentMethodsFetching.value || isZuoraAccountFetching.value)
const isRequiredDataError = computed(
  () => isAvailablePaymentMethodsError.value || isExistingPaymentMethodsError.value || isZuoraAccountError.value
)

const paymentFormRef = ref(null)
const termsAccepted = ref(false)
const isSubmittingNewSubscription = ref(false)

const language = computed(() => store.state.locale.language)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const paymentEnabled = computed(() => {
  return (
    termsAccepted.value &&
    paymentFormRef.value?.selectedPaymentMethod !== null &&
    paymentFormRef.value?.isBillingFormValid
  )
})
const formZipCode = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.zipCode)
const formCountry = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.country)
const formState = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.state)
const formTaxId = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.taxId)
const noTaxId = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.noTaxId)

const { studioPlusPrice, isLoading, isFetching } = useStudioPlusPrice({
  selectedAccommodationId: { value: currentAccommodationId },
  countryCode: formCountry,
  postalCode: formZipCode,
  countryState: formState,
  taxId: noTaxId.value ? null : formTaxId,
  currency,
})

const { displayNotification } = useToastNotifications()

onMounted(() => {
  appAnalytics.track(appAnalytics.events.SP_PAYMENT_VISITED, {
    item_id: selectedAccommodationId.value,
    itemName: currentAccommodation?.value?.name,
    language: language.value,
    studioUserId: userDetails?.value?.id,
    email: userDetails?.value?.email,
    firstName: userDetails?.value?.firstName,
    lastName: userDetails?.value?.lastName,
  })
})

const onStartFreeTrial = () => {
  appAnalytics.track(appAnalytics.events.SP_START_PLUS_SUBSCRIPTION_BUTTON_CLICKED, {
    item_id: selectedAccommodationId.value,
    currency: currency.value,
  })
  isSubmittingNewSubscription.value = true
  paymentFormRef.value?.validateForm()
}

const onFormValidationSuccess = () => {
  sendStudioPlusNewSubscription(
    {
      accommodationId: isReactivate ? reactivateAccommodation : selectedAccommodationId.value,
      itemName: currentAccommodation?.value?.name,
      email: userDetails?.value?.email,
      language: language?.value,
      studioUserId: userDetails?.value?.id,
      firstName: userDetails?.value?.firstName,
      lastName: userDetails?.value?.lastName,
      isFreeTrial: false,
    },
    {
      onSuccess: data => {
        if (isReactivate) {
          queryClient.invalidateQueries({ queryKey: ['studioPlusCampaign'] })
          router.push({ name: reactivationBackPath.value, query: { reactivate: true } })
          appAnalytics.track(appAnalytics.events.SP_SUBSCRIPTION_REACTIVATED, {
            item_id: reactivateAccommodation,
            price: studioPlusPrice.value.price,
            language: language?.value,
            studioUserId: userDetails?.value?.id,
            email: userDetails?.value?.email,
            firstName: userDetails?.value?.firstName,
            lastName: userDetails?.value?.lastName,
            reactivation_date: reactivationDate.value,
            auto_renewal_date: new Date(data?.data?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }),
          })
        } else {
          if (data.status === 204 && zuoraAccountData.value) {
            onFormValidationSuccess()
          } else {
            queryClient.invalidateQueries({ queryKey: ['studioPlusCampaign'] })
            appAnalytics.track(appAnalytics.events.SP_PAYMENT_SUCCEED, {
              item_id: selectedAccommodationId.value,
              price: studioPlusPrice.value.price,
              language: language?.value,
              studioUserId: userDetails?.value?.id,
              email: userDetails?.value?.email,
              firstName: userDetails?.value?.firstName,
              lastName: userDetails?.value?.lastName,
              subscription_date: new Date(data.data.subscriptionStartDate).toLocaleDateString(
                store.state.locale.language,
                {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }
              ),
              renewal_date: new Date(data?.data?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }),
            })
            isSubmittingNewSubscription.value = false
            window.localStorage.setItem(`contact_info_banner_${selectedAccommodationId.value}`, 'true')
            window.dispatchEvent(
              new CustomEvent('contact_info_banner_localstorage-changed', {
                detail: {
                  storage: localStorage.getItem(`contact_info_banner_${selectedAccommodationId.value}`),
                },
              })
            )
            router.push({ name: 'home' })
          }
        }
      },
      onError: () => {
        isSubmittingNewSubscription.value = false
        displayNotification({
          message: 'subscriptions.details.transactionHistory.error',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
      },
    }
  )
}

const onFormValidationError = () => {
  appAnalytics.track(appAnalytics.events.SP_PAYMENT_FAILED, {
    item_id: selectedAccommodationId.value,
  })
  isSubmittingNewSubscription.value = false
  displayNotification({
    message: 'subscriptions.details.transactionHistory.error',
    isTranslationKey: true,
    type: 'error',
  })
}
</script>

<style scoped>
.sp-payment-grid {
  display: block;

  @media (min-width: theme('screens.md')) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas:
      'payment-accommodation payment-footer'
      'payment-form payment-footer';
    column-gap: 4rem;
  }
}
.sp-payment-form {
  grid-area: payment-form;
}
.sp-payment-footer {
  grid-area: payment-footer;
}
.sp-payment-accommodation {
  grid-area: payment-accommodation;
}
.terms :deep(a) {
  @apply tw-text-blue-700;
}
.sticky {
  position: sticky;
  top: 120px;
  right: 0;
}
</style>
