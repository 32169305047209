<template>
  <div class="tw-my-4">
    <!-- TO BE REMOVED -->
    <div class="tw-bg-red-200 tw-rounded-lg tw-py-3 tw-px-3 tw-flex tw-items-center tw-mb-6">
      <BaseIcon :height="20" :width="20" icon-name="information" class="tw-mr-2 tw-fill-red-700">
        <Information />
      </BaseIcon>
      <p class="tw-text-base">
        {{ $t('tbs_maintenance_text') }}
      </p>
    </div>
    <PropertyImages :accommodation="accommodationBasicInfo" :images="images" />
  </div>
</template>

<script setup>
import { watch } from 'vue'

import PropertyImages from '@/components/propertyDetails/propertyImages/PropertyImages.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Information from '@/components/icons/Information.vue'

import { useAccommodationBasicInfo, useGetImages, useGetMainImage } from '@/components/propertyDetails/queries/index.js'
import { usePropertyImages } from '@/components/propertyDetails/propertyImages/usePropertyImages.js'
import { selectedAccommodationId } from '@/layouts/queries'

const { resetDeletingIds } = usePropertyImages()

const { accommodationBasicInfo } = useAccommodationBasicInfo(selectedAccommodationId)
const { images } = useGetImages(selectedAccommodationId)
const { mainImage } = useGetMainImage(selectedAccommodationId)

watch(images, (newValue, oldValue) => {
  if (oldValue.length > newValue.length) {
    resetDeletingIds()
  }
})

watch(mainImage, newMainImage => {
  if (!newMainImage?.height || !newMainImage?.width || !newMainImage?.imagePath) return
  const mainImageCached = new Image()
  mainImageCached.src = `https://imgcy.trivago.com/c_limit,d_dummy.jpeg,f_auto,c_scale,h_${newMainImage.height},q_auto,w_${newMainImage.width}/${newMainImage.imagePath}`
})
</script>
