<template>
  <div>
    <h3 class="tw-font-bold tw-mb-1">{{ $t('business_studio_plus_checkout_payment_details_header') }}</h3>
    <div class="tw-px-4 tw-py-5 tw-pb-4 tw-px-4 tw-bg-red-200 tw-mb-4 tw-rounded-lg" v-if="errorInfo">
      <p class="tw-text-sm">{{ $t(errorInfo) }}</p>
    </div>
    <BaseCard noPadding class="tw-mb-6">
      <div v-if="isLoading" class="tw-p-4">
        <div class="tw-animate-pulse tw-w-full tw-rounded-lg tw-bg-gray-300 tw-h-[150px]" />
      </div>
      <PaymentMethodForm
        v-else
        :currency="currency"
        :accommodationId="currentAccommodationId"
        :productName="productName"
        :fetchingPaymentMethods="isFetching"
        :existingPaymentMethods="existingPaymentMethodsData"
        :availablePaymentMethods="availablePaymentMethodsData"
        :selectedPaymentMethod="selectedPaymentMethod"
        @onShowNewFormCancel="onNewPaymentMethodFormCancel"
        @onSelectedValueChange="e => (selectedPaymentMethod = e)"
        @onZuoraCallbackSuccess="onZuoraCallbackSuccess"
        @onZuoraCallbackError="onZuoraCallbackError"
        @onZuoraClientValidationError="onZuoraClientValidationError"
        @setError="error => setError(error)"
      />
    </BaseCard>
    <h3 class="tw-font-bold tw-mb-1" ref="billingFormTitle">{{ $t('checkoutPage.alreadyAuthorized.billingInfo') }}</h3>
    <BaseCard noPadding class="tw-flex tw-flex-col tw-gap-3 tw-px-4 tw-py-6">
      <BillingInformationForm
        ref="billingForm"
        :initialBillingFormValues="existingBillingInformation"
        @onValidateAllSuccess="onValidateBillingSuccess"
        @onValidateAllError="onValidateBillingError"
        @setBillingFormValid="setBillingFormValid"
      />
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BillingInformationForm from '@/components/payment/BillingInformationForm.vue'
import PaymentMethodForm from '@/components/payment/PaymentMethodForm.vue'
import {
  useAvailablePaymentMethods,
  useErrorMetrics,
  useExistingPaymentMethods,
  useZuoraAccount,
  useZuoraNewAccountMutation,
  useZuoraUpdateAccountMutation,
} from '@/components/payment/queries'
import { countries } from '@/constants/countries.js'
import zuoraMetricConstants from '@/constants/zuoraMetricConstants'

const emit = defineEmits(['onFormValidationError', 'onFormValidationSuccess', 'onZuoraHostedPageLoadError'])

const store = useStore()

const props = defineProps({
  currency: {
    type: String,
    default: 'EUR',
  },
  accommodationId: Number,
  productName: String,
})
const currencyCode = computed(() => props.currency)
const currentAccommodationId = computed(() => props.accommodationId)
const { existingPaymentMethodsData } = useExistingPaymentMethods(currentAccommodationId)
const { isFetching, availablePaymentMethodsData, refetch, isLoading } = useAvailablePaymentMethods(
  currentAccommodationId,
  currencyCode
)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const token = computed(() => store.state.session?.studioToken)

const { zuoraAccountData } = useZuoraAccount(token)
const { createNewZuoraAccount } = useZuoraNewAccountMutation()
const { updateZuoraAccount } = useZuoraUpdateAccountMutation()
const { sendErrorMetrics } = useErrorMetrics()
const language = computed(() => store.state.locale.language)

const billingForm = ref(null)
const billingFormTitle = ref(null)
const selectedPaymentMethod = ref(null)
const newBillingInformation = ref(null)
const isSubmittingForm = ref(false)
const isZuoraHppLoadError = ref(false)
const errorInfo = ref(false)
const isBillingFormValid = ref(true)
const countriesWithState = ['US', 'MX', 'CA', 'BR']

const defaultPaymentMethodId = computed(
  () => existingPaymentMethodsData.value?.find(paymentMethod => paymentMethod.isDefault)?.id
)

const existingBillingInformation = computed(() => {
  if (
    !zuoraAccountData.value ||
    zuoraAccountData.value?.basicInfo === null ||
    zuoraAccountData.value?.billToContact === null
  )
    return null
  const countryCode = countries.find(country => country.name === zuoraAccountData.value?.billToContact?.country)?.code
  return {
    companyName: zuoraAccountData.value.basicInfo.name,
    email: zuoraAccountData.value.billToContact.workEmail,
    firstName: zuoraAccountData.value.billToContact.firstName,
    lastName: zuoraAccountData.value.billToContact.lastName,
    address: zuoraAccountData.value.billToContact.address1,
    city: zuoraAccountData.value.billToContact.city,
    zipCode: zuoraAccountData.value.billToContact.zipCode,
    country: countryCode,
    state: zuoraAccountData.value.billToContact.state,
    taxId: zuoraAccountData.value.taxInfo?.exemptCertificateId,
    noTaxId: zuoraAccountData.value.taxInfo?.exemptCertificateId ? false : true,
  }
})

const isSelectedPaymentMethodNew = computed(
  () => selectedPaymentMethod.value === 'CREDIT_CARD' || selectedPaymentMethod.value === 'SEPA'
)

const setError = error => {
  errorInfo.value = error
}

const forceRender = async () => {
  await nextTick()
  refetch()
  isZuoraHppLoadError.value = false
}

const onNewPaymentMethodFormCancel = () => {
  errorInfo.value = false
  if (isSelectedPaymentMethodNew.value) {
    selectedPaymentMethod.value = defaultPaymentMethodId.value
  }
}

const validateForm = () => {
  if (isSelectedPaymentMethodNew.value && isZuoraHppLoadError.value) {
    emit('onZuoraHostedPageLoadError')
    return
  }
  isSubmittingForm.value = true
  billingForm.value?.validateAll()
}

const onValidateBillingSuccess = billingInfo => {
  newBillingInformation.value = billingInfo
  if (!selectedPaymentMethod.value) {
    emit('onFormValidationError')
    isSubmittingForm.value = false
    return
  }
  if (isSelectedPaymentMethodNew.value) {
    Z.submit()
    return
  }
  if (selectedPaymentMethod.value !== defaultPaymentMethodId.value) {
    sendZuoraAccount(selectedPaymentMethod.value)
    return
  }
  sendZuoraAccount()
}

const onValidateBillingError = () => {
  isSubmittingForm.value = false
  emit('onFormValidationError')
}

const setBillingFormValid = isValid => {
  isBillingFormValid.value = isValid
}

const onZuoraClientValidationError = () => {
  isSubmittingForm.value = false
  forceRender()
  emit('onFormValidationError')
}

const onZuoraCallbackSuccess = async refId => {
  sendZuoraAccount(refId)
}

const onZuoraCallbackError = (withRerender = false) => {
  if (isSubmittingForm.value) {
    emit('onFormValidationError')
  } else {
    if (withRerender) {
      forceRender()
    }
    isZuoraHppLoadError.value = true
  }
  isSubmittingForm.value = false
}

const sendZuoraAccount = async refId => {
  if (!existingBillingInformation.value) {
    const body = {
      name: newBillingInformation.value?.companyName,
      organization_id__c: selectedOrganisationId.value,
      thm_account_type__c: 'business studio',
      billCycleDay: 0,
      currency: currencyCode.value,
      thm_item_id__c: currentAccommodationId.value,
      autoPay: true,
      hpmCreditCardPaymentMethodId: refId,
      billToContact: {
        address1: newBillingInformation.value?.address,
        city: newBillingInformation.value?.city,
        country: newBillingInformation.value?.country,
        firstName: newBillingInformation.value?.firstName,
        lastName: newBillingInformation.value?.lastName,
        state: newBillingInformation.value?.state ?? '',
        workEmail: newBillingInformation.value?.email,
        zipCode: newBillingInformation.value?.zipCode,
      },
      taxInfo: {
        exemptCertificateId: newBillingInformation.value?.taxId ?? '',
      },
    }

    await createNewZuoraAccount(
      { body, language: language.value },
      {
        onSuccess: data => {
          if (data?.data?.success) {
            emit('onFormValidationSuccess')
            isSubmittingForm.value = false
          } else {
            forceRender()
            emit('onFormValidationError')
            isSubmittingForm.value = false
            sendErrorMetrics({
              itemId: props.accommodationId,
              productName: zuoraMetricConstants.productName.plus,
              code: zuoraMetricConstants.metricErrorCodes.billingInformation,
              message: data?.data,
            })
          }
        },
        onError: response => {
          emit('onFormValidationError')
          isSubmittingForm.value = false
          sendErrorMetrics({
            itemId: props.accommodationId,
            productName: zuoraMetricConstants.productName.plus,
            code: zuoraMetricConstants.metricErrorCodes.billingInformation,
            message: response.response.data?.message,
          })
        },
      }
    )
  } else {
    if (!newBillingInformation.value) return
    const body = {
      billToContact: {},
      taxInfo: {},
    }
    if (refId) body.defaultPaymentMethodId = refId
    if (
      newBillingInformation.value.companyName &&
      newBillingInformation.value.companyName !== existingBillingInformation.value.companyName
    )
      body.name = newBillingInformation.value.companyName
    if (
      newBillingInformation.value.address &&
      newBillingInformation.value.address !== existingBillingInformation.value.address
    )
      body.billToContact.address1 = newBillingInformation.value.address
    if (newBillingInformation.value.city && newBillingInformation.value.city !== existingBillingInformation.value.city)
      body.billToContact.city = newBillingInformation.value.city
    if (
      newBillingInformation.value.firstName &&
      newBillingInformation.value.firstName !== existingBillingInformation.value.firstName
    )
      body.billToContact.firstName = newBillingInformation.value.firstName
    if (
      newBillingInformation.value.lastName &&
      newBillingInformation.value.lastName !== existingBillingInformation.value.lastName
    )
      body.billToContact.lastName = newBillingInformation.value.lastName
    if (
      newBillingInformation.value.email &&
      newBillingInformation.value.email !== existingBillingInformation.value.email
    )
      body.billToContact.workEmail = newBillingInformation.value.email

    body.billToContact.zipCode = newBillingInformation.value.zipCode ?? existingBillingInformation.value.zipCode
    body.billToContact.country = newBillingInformation.value.country ?? existingBillingInformation.value.country
    body.billToContact.state = countriesWithState.includes(body.billToContact.country)
      ? newBillingInformation.value.state
      : ''
    body.taxInfo.exemptCertificateId = newBillingInformation.value.taxId ?? existingBillingInformation.value.taxId

    if (Object.keys(body).length > 0) {
      await updateZuoraAccount(
        { body, language: language.value },
        {
          onSuccess: data => {
            if (data?.data?.success) {
              emit('onFormValidationSuccess')
              isSubmittingForm.value = false
            } else {
              forceRender()
              emit('onFormValidationError')
              isSubmittingForm.value = false
              sendErrorMetrics({
                itemId: props.accommodationId,
                productName: zuoraMetricConstants.productName.plus,
                code: zuoraMetricConstants.metricErrorCodes.billingInformation,
                message: data?.data,
              })
            }
          },
          onError: response => {
            emit('onFormValidationError')
            isSubmittingForm.value = false
            sendErrorMetrics({
              itemId: props.accommodationId,
              productName: zuoraMetricConstants.productName.plus,
              code: zuoraMetricConstants.metricErrorCodes.billingInformation,
              message: response.response.data?.message,
            })
          },
        }
      )
    } else {
      emit('onFormValidationSuccess')
      isSubmittingForm.value = false
    }
  }
}

onMounted(() => {
  if (defaultPaymentMethodId.value) selectedPaymentMethod.value = defaultPaymentMethodId.value
})

defineExpose({
  validateForm,
  selectedPaymentMethod,
  existingBillingInformation,
  isBillingFormValid,
  billingForm,
})
</script>
