<template>
  <section ref="studioPlusBenefits">
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto tw-pb-8"
    >
      <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start">
        <img :src="StudioPlusLogo" alt="rate-connect-logo" height="28" class="tw-mb-4" />
        <h1
          class="tw-font-bold tw-text-black tw-text-[28px] lg:tw-text-[40px] tw-leading-[35px] lg:tw-leading-[50px] tw-text-center md:tw-text-left"
        >
          {{ $t('studio_plus_mp_subheader') }}
        </h1>
      </div>
    </div>

    <!-- Web Version -->
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto"
    >
      <div class="md:tw-flex tw-hidden tw-flex-wrap tw-w-full tw-text-gray-800 tw-gap-6">
        <div
          v-for="(benefit, index) in webBenefits"
          :key="index"
          class="tw-bg-white tw-flex tw-items-center tw-w-[calc(50%-12px)] tw-rounded-lg tw-px-4 tw-py-6"
        >
          <div class="tw-min-w-[75px] tw-mr-2">
            <img
              :src="benefit.image"
              :alt="$t(benefit.title)"
              class="tw-mx-auto"
              :class="index === 2 || index === 3 ? 'tw-h-[70px]' : 'tw-h-[81px]'"
            />
          </div>
          <div class="tw-flex tw-flex-col">
            <p class="tw-font-bold tw-text-2xl tw-mb-2 tw-text-blue-700">
              {{ $t(benefit.title) }}
            </p>
            <p class="tw-text-lg">{{ $t(benefit.text) }}</p>
          </div>
        </div>
      </div>
      <p class="tw-text-lg tw-mt-12 md:tw-flex tw-hidden">
        {{ $t('studio_plus_info_text') }}
      </p>
    </div>

    <!-- Mobile Version -->
    <div
      class="tw-w-full tw-flex md:tw-hidden tw-justify-between tw-text-gray-800 tw-flex-col md:tw-flex-row tw-w-auto"
    >
      <div
        class="inner carousel-mobile"
        ref="inner"
        :class="{ 'tw-ml-0 md:tw-ml-8': isInitialSlide }"
        :style="innerStyles"
      >
        <div v-for="(benefit, index) in benefits" :key="index" :id="benefit.id" class="card">
          <div class="tw-min-w-[75px]">
            <img :src="benefit.image" :alt="$t(benefit.title)" class="tw-h-[70px] tw-w-[74px]" />
          </div>
          <div>
            <p class="tw-font-bold tw-text-base tw-mb-2 tw-text-blue-700">
              {{ $t(benefit.title) }}
            </p>
            <p class="tw-text-sm">{{ $t(benefit.text) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex md:tw-hidden tw-flex-col">
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-5">
        <button
          v-for="(_, index) in benefits"
          :key="index"
          class="tw-w-[10px] tw-mx-[5px] tw-h-[10px] tw-rounded-full tw-mb-6"
          :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
        />
      </div>
      <p class="tw-px-4 tw-text-sm">
        {{ $t('studio_plus_info_text') }}
      </p>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import TouchEvent from '@/utils/touchEvents.js'

const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)
const benefitOne = new URL('/src/assets/img/studio-plus-landing/lightbulb.svg', import.meta.url)
const benefitTwo = new URL('/src/assets/img/studio-plus-landing/sp-benefit-two.svg', import.meta.url)
const benefitThree = new URL('/src/assets/img/studio-plus-landing/sp-benefit-three.svg', import.meta.url)
const benefitFour = new URL('/src/assets/img/studio-plus-landing/sp-benefit-four.svg', import.meta.url)

const webBenefits = ref([
  {
    title: 'studio_plus_mp_benefit_1_header',
    text: 'studio_plus_mp_benefit_1_text',
    image: benefitOne,
  },
  {
    title: 'studio_plus_mp_benefit_2_header',
    text: 'studio_plus_mp_benefit_2_text',
    image: benefitTwo,
  },
  {
    title: 'studio_plus_mp_benefit_3_header',
    text: 'studio_plus_mp_benefit_3_text',
    image: benefitThree,
  },
  {
    title: 'studio_plus_mp_benefit_4_header',
    text: 'studio_plus_mp_benefit_4_text',
    image: benefitFour,
  },
])
const benefits = ref([
  {
    title: 'studio_plus_mp_benefit_1_header',
    text: 'studio_plus_mp_benefit_1_text',
    image: benefitOne,
    id: 'benefit_1',
  },
  {
    title: 'studio_plus_mp_benefit_2_header',
    text: 'studio_plus_mp_benefit_2_text',
    image: benefitTwo,
    id: 'benefit_2',
  },
  {
    title: 'studio_plus_mp_benefit_3_header',
    text: 'studio_plus_mp_benefit_3_text',
    image: benefitThree,
    id: 'benefit_3',
  },
  {
    title: 'studio_plus_mp_benefit_4_header',
    text: 'studio_plus_mp_benefit_4_text',
    image: benefitFour,
    id: 'benefit_4',
  },
])

const studioPlusBenefits = ref(null)
const touchEventBenefits = ref(null)
const current = ref(0)
const timer = ref('')
const isInitialSlide = ref(true)
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)
const scrollCenter = ref(0)

watch(current, newCurrent => {
  if (newCurrent === 1 && isInitialSlide.value) {
    isInitialSlide.value = false
  }
})
onMounted(async () => {
  await nextTick()
  setStep()
  autoplay()

  document.addEventListener('touchstart', event => {
    touchEventBenefits.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(-${scrollCenter.value}px)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = benefits.value.length

  step.value = `${innerWidth / totalCards}`
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElementId = window.document.querySelector('.carousel-mobile').firstElementChild.id
    const item = window.document.getElementById(firstElementId)
    item.parentNode.appendChild(item)
    if (firstElementId === 'benefit_1') current.value = 1
    if (firstElementId === 'benefit_2') current.value = 2
    if (firstElementId === 'benefit_3') current.value = 3
    if (firstElementId === 'benefit_4') current.value = 0
    resetTranslate()
    transitioning.value = false
  })
}

const moveLeft = () => {
  innerStyles.value = {
    transform: `translateX(-${step.value}px)`,
  }
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElementId = window.document.querySelector('.carousel-mobile').lastElementChild.id
    const item = window.document.getElementById(lastElementId)
    item.parentNode.prepend(item)

    if (lastElementId === 'benefit_1') current.value = 0
    if (lastElementId === 'benefit_2') current.value = 1
    if (lastElementId === 'benefit_3') current.value = 2
    if (lastElementId === 'benefit_4') current.value = 3

    resetTranslate()
    transitioning.value = false
  })
}

const moveRight = () => {
  const baseStep = step.value - scrollCenter.value
  innerStyles.value = {
    transform: `translateX(${baseStep - scrollCenter.value}px)`,
  }
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (studioPlusBenefits.value?.contains(event.target)) {
    if (!touchEventBenefits.value) {
      return
    }
    touchEventBenefits.value.setEndEvent(event)

    if (touchEventBenefits.value.isSwipeRight()) {
      prev()
    } else if (touchEventBenefits.value.isSwipeLeft()) {
      next()
    }
    touchEventBenefits.value = null
  }
}
</script>
<style scoped>
.carousel-mobile {
  display: block;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
.card {
  @apply tw-bg-white tw-rounded-lg tw-flex tw-mx-4 tw-my-0 tw-p-2.5 tw-gap-1 tw-min-w-[300px] tw-w-[300px] xs:tw-min-w-[330px] xs:tw-w-[330px] tw-min-h-[85px] tw-flex tw-items-center;
  margin-right: 10px;
  display: inline-flex;
  white-space: wrap;
}
</style>
