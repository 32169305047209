<template>
  <div>
    <h1
      class="tw-font-bold tw-mb-8"
      :class="
        isChangingCampaign ? 'tw-text-xl tw-text-center md:tw-text-left' : 'tw-text-2xl tw-mx-auto tw-text-center'
      "
    >
      {{ $t('rc_setup_commission_header') }}
    </h1>
    <p
      class="commission-benefits tw-max-w-screen-md md:tw-max-w-fit tw-mb-8"
      :class="isChangingCampaign ? 'tw-text-center md:tw-text-left tw-px-4 md:tw-px-0' : 'tw-mx-auto'"
      v-html="selectedCampaignType === 'netCpa' ? $t('rc_ncpa_commission_benefits') : $t('rc_gcpa_commission_benefits')"
    ></p>
    <ul
      class="tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-mb-8"
      :class="{ 'tw-my-10': isChangingCampaign }"
    >
      <li
        v-for="commissionLevel in eligibleCommissionLevels"
        :key="commissionLevel"
        class="tw-mb-4 md:tw-mb-0 last:tw-mb-0"
      >
        <button @click="updateCommission(commissionLevel)" class="tw-w-full">
          <CommissionLevelSelectCardNew
            :isRecommended="commissionLevel === 18"
            :isSelected="selectedCommissionLevel === commissionLevel"
            :commissionValue="commissionLevel"
          />
        </button>
      </li>
    </ul>
    <p v-if="selectedCampaignType === 'netCpa'" class="tw-text-center tw-text-gray-700 tw-mt-8 tw-mb-[48px]">
      {{ $t('rc_pay_per_stay_billing_footnote') }}
    </p>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'

import CommissionLevelSelectCardNew from '../CommissionLevelSelectCardNew.vue'

const props = defineProps({
  eligibleCommissionLevels: {
    type: Array,
    required: true,
    default: () => [],
  },
  selectedCommissionLevel: {
    type: Number,
    required: false,
    default: 18,
  },
  selectedCampaignType: {
    type: String,
    required: true,
    default: 'netCpa',
    validator(value) {
      return ['netCpa', 'cpa'].includes(value)
    },
  },
  isChangingCampaign: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['onSelectedCommissionChange'])

const updateCommission = level => {
  emit('onSelectedCommissionChange', level)
}

onMounted(() => {
  if (
    props.selectedCommissionLevel === 12 ||
    props.selectedCommissionLevel === 25 ||
    props.selectedCommissionLevel === 15 ||
    props.selectedCommissionLevel === 20
  ) {
    updateCommission(18)
  }
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  })
})
</script>

<style scoped>
.commission-benefits :deep(ul) {
  @apply tw-text-left tw-flex tw-flex-col tw-gap-1 tw-list-disc tw-px-5;
}
</style>
