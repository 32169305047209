<template>
  <div v-if="isLoading || isExistingPaymentMethodsLoading || isAvailablePaymentMethodsLoading">
    <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80 tw-mt-6" aria-hidden="true"></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-700 tw-mt-6" v-html="$t('errors.wrong_data_from_app')" />
  <div v-else class="tw-mt-6 tw-flex tw-flex-col">
    <a
      class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-700 tw-mb-6"
      @click="router.push({ name: 'settings-payment-methods', query: { editPayment: true } })"
      @keydown.enter="router.push({ name: 'settings-settings-payment-methods' })"
      >← {{ $t('setup_back') }}</a
    >
    <div class="tw-flex">
      <div class="tw-w-1/2 tw-mr-3">
        <BaseCard class="tw-h-fit" noPadding>
          <div class="tw-flex tw-flex-col tw-gap-3 tw-p-6">
            <h3 class="tw-font-bold tw-mb-1 tw-text-xl" ref="billingFormTitle">
              {{ $t('paymentMethods.billingAddress.heading') }}
            </h3>

            <BillingInformationForm
              ref="billingForm"
              :initialBillingFormValues="existingBillingInformation"
              @onValidateAllSuccess="onValidateBillingSuccess"
              @onValidateAllError="onValidateBillingError"
              @setBillingFormValid="setBillingFormValid"
            />
          </div>
        </BaseCard>
      </div>
      <div class="tw-w-1/2 tw-flex tw-flex-col tw-ml-3">
        <div class="sticky">
          <BaseCard class="tw-h-fit" noPadding>
            <div class="tw-flex tw-flex-col tw-gap-3">
              <h3 class="tw-font-bold tw-text-xl tw-mt-6 tw-mx-4 tw-mb-1">
                {{ $t('paymentMethods.heading') }}
              </h3>
              <div class="tw-mx-6 tw-py-5 tw-px-4 tw-bg-red-200 tw-rounded-lg" v-if="errorInfo">
                <p class="tw-text-sm">{{ $t(errorInfo) }}</p>
              </div>
              <div
                v-if="isExistingPaymentMethodsLoading || isAvailablePaymentMethodsLoading"
                class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-h-80 tw-mx-6 tw-mb-6"
                aria-hidden="true"
              />
              <PaymentMethodForm
                v-else
                source="billing-information-form"
                :currency="currency"
                :accommodationId="selectedAccommodationId"
                :productName="zuoraMetricConstants.productName.settings"
                :fetchingPaymentMethods="isFetching || isExistingPaymentMethodsLoading"
                :existingPaymentMethods="existingPaymentMethodsData"
                :availablePaymentMethods="availablePaymentMethodsData"
                :selectedPaymentMethod="selectedPaymentMethod"
                @onSelectedValueChange="e => (selectedPaymentMethod = e)"
                @onShowNewFormCancel="onNewPaymentMethodFormCancel"
                @onZuoraCallbackSuccess="onZuoraCallbackSuccess"
                @onZuoraCallbackError="onZuoraCallbackError"
                @onZuoraClientValidationError="onZuoraClientValidationError"
                @setError="error => setError(error)"
              />
            </div>
          </BaseCard>
          <div class="tw-flex tw-justify-end tw-gap-6 tw-mt-6">
            <GhostButton @click="router.push({ name: 'settings-payment-methods', query: { editPayment: true } })">{{
              $t('payment_method_cancel_cta')
            }}</GhostButton>
            <MainButton @click="updateBillingInformation">
              <span v-if="!isSubmittingForm">{{ $t('payment_method_update_cta') }}</span>
              <span v-if="isSubmittingForm">
                <RCInlineLoader color="#fff" />
              </span>
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BillingInformationForm from '@/components/payment/BillingInformationForm.vue'
import PaymentMethodForm from '@/components/payment/PaymentMethodForm.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'

import {
  useAvailablePaymentMethods,
  useErrorMetrics,
  useExistingPaymentMethods,
  useZuoraAccount,
  useZuoraUpdateAccountMutation,
} from '@/components/payment/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { countries } from '@/constants/countries.js'

import useToastNotifications from '@/components/notifications/useToastNotifications'
import zuoraMetricConstants from '@/constants/zuoraMetricConstants'

const router = useRouter()
const store = useStore()

const billingForm = ref(null)
const billingFormTitle = ref(null)
const selectedPaymentMethod = ref(null)
const isBillingFormValid = ref(true)
const newBillingInformation = ref(null)
const isZuoraHppLoadError = ref(false)
const isSubmittingForm = ref(false)
const errorInfo = ref(false)
const countriesWithState = ['US', 'MX', 'CA', 'BR']

const { displayNotification } = useToastNotifications()
const { sendErrorMetrics } = useErrorMetrics()

const token = computed(() => store.state.session?.studioToken)
const { isLoading, isError, zuoraAccountData } = useZuoraAccount(token)
const { existingPaymentMethodsData, isLoading: isExistingPaymentMethodsLoading } =
  useExistingPaymentMethods(selectedAccommodationId)
const { updateZuoraAccount } = useZuoraUpdateAccountMutation()
const language = computed(() => store.state.locale.language)
const currencyCode = computed(() => store.state.currency)
const {
  isFetching,
  isLoading: isAvailablePaymentMethodsLoading,
  availablePaymentMethodsData,
  refetch,
} = useAvailablePaymentMethods(selectedAccommodationId, currencyCode)

const setBillingFormValid = isValid => {
  isBillingFormValid.value = isValid
}

const setError = error => {
  errorInfo.value = error
}

const onZuoraCallbackSuccess = async refId => {
  sendZuoraAccount(refId)
}

const forceRender = async () => {
  await nextTick()
  refetch()
  isZuoraHppLoadError.value = false
  isSubmittingForm.value = false
}

const onZuoraClientValidationError = () => {
  isSubmittingForm.value = false
  forceRender()
  displayNotification({
    message: 'subscriptions.details.transactionHistory.error',
    isTranslationKey: true,
    type: 'error',
  })
}

const onZuoraCallbackError = (withRerender = false) => {
  if (isSubmittingForm.value) {
    displayNotification({
      message: 'subscriptions.details.transactionHistory.error',
      isTranslationKey: true,
      type: 'error',
    })
  } else {
    if (withRerender) {
      forceRender()
    }
    isZuoraHppLoadError.value = true
  }
  isSubmittingForm.value = false
}

const onNewPaymentMethodFormCancel = () => {
  errorInfo.value = false
  if (isSelectedPaymentMethodNew.value) {
    selectedPaymentMethod.value = defaultPaymentMethodId.value
  }
}
const existingBillingInformation = computed(() => {
  if (!zuoraAccountData.value) return null
  const countryCode = countries.find(country => country.name === zuoraAccountData.value?.billToContact?.country)?.code
  return {
    companyName: zuoraAccountData.value.basicInfo.name,
    email: zuoraAccountData.value.billToContact.workEmail,
    firstName: zuoraAccountData.value.billToContact.firstName,
    lastName: zuoraAccountData.value.billToContact.lastName,
    address: zuoraAccountData.value.billToContact.address1,
    city: zuoraAccountData.value.billToContact.city,
    zipCode: zuoraAccountData.value.billToContact.zipCode,
    country: countryCode,
    state: zuoraAccountData.value.billToContact.state,
    taxId: zuoraAccountData.value.taxInfo?.exemptCertificateId,
    noTaxId: zuoraAccountData.value.taxInfo?.exemptCertificateId ? false : true,
  }
})

const defaultPaymentMethodId = computed(
  () => existingPaymentMethodsData.value?.find(paymentMethod => paymentMethod.isDefault)?.id
)

const isSelectedPaymentMethodNew = computed(
  () => selectedPaymentMethod.value === 'CREDIT_CARD' || selectedPaymentMethod.value === 'SEPA'
)

watch(defaultPaymentMethodId, newVal => {
  selectedPaymentMethod.value = newVal
})

onMounted(() => {
  if (defaultPaymentMethodId.value) {
    selectedPaymentMethod.value = defaultPaymentMethodId.value
  }
  window.scrollTo({
    behavior: 'smooth',
    top: billingFormTitle.value?.getBoundingClientRect().top + window.scrollY - 125,
  })
})

const updateBillingInformation = () => {
  if (isSelectedPaymentMethodNew.value && isZuoraHppLoadError.value) {
    displayNotification({
      message: 'subscriptions.details.transactionHistory.error',
      isTranslationKey: true,
      type: 'error',
    })
    return
  }
  isSubmittingForm.value = true
  billingForm.value?.validateAll()
}

const onValidateBillingSuccess = billingInfo => {
  newBillingInformation.value = billingInfo
  if (!selectedPaymentMethod.value) {
    isSubmittingForm.value = false
    return
  }
  if (isSelectedPaymentMethodNew.value) {
    Z.submit()
    return
  }
  if (selectedPaymentMethod.value !== defaultPaymentMethodId.value) {
    sendZuoraAccount(selectedPaymentMethod.value)
    return
  }
  sendZuoraAccount()
}

const onValidateBillingError = () => {
  isSubmittingForm.value = false
  window.scrollTo({
    behavior: 'smooth',
    top: billingFormTitle.value?.getBoundingClientRect().top + window.scrollY - 125,
  })
}

const sendZuoraAccount = async refId => {
  const body = {
    billToContact: {},
    taxInfo: {},
  }
  if (refId) body.defaultPaymentMethodId = refId
  if (
    newBillingInformation.value.companyName &&
    newBillingInformation.value.companyName !== existingBillingInformation.value.companyName
  )
    body.name = newBillingInformation.value.companyName
  if (
    newBillingInformation.value.address &&
    newBillingInformation.value.address !== existingBillingInformation.value.address
  )
    body.billToContact.address1 = newBillingInformation.value.address
  if (newBillingInformation.value.city && newBillingInformation.value.city !== existingBillingInformation.value.city)
    body.billToContact.city = newBillingInformation.value.city
  if (
    newBillingInformation.value.firstName &&
    newBillingInformation.value.firstName !== existingBillingInformation.value.firstName
  )
    body.billToContact.firstName = newBillingInformation.value.firstName
  if (
    newBillingInformation.value.lastName &&
    newBillingInformation.value.lastName !== existingBillingInformation.value.lastName
  )
    body.billToContact.lastName = newBillingInformation.value.lastName
  if (newBillingInformation.value.email && newBillingInformation.value.email !== existingBillingInformation.value.email)
    body.billToContact.workEmail = newBillingInformation.value.email

  body.billToContact.zipCode = newBillingInformation.value.zipCode ?? existingBillingInformation.value.zipCode
  body.billToContact.country = newBillingInformation.value.country ?? existingBillingInformation.value.country
  body.billToContact.state = countriesWithState.includes(body.billToContact.country)
    ? newBillingInformation.value.state
    : ''
  body.taxInfo.exemptCertificateId = newBillingInformation.value.taxId ?? existingBillingInformation.value.taxId

  if (Object.keys(body).length > 0) {
    await updateZuoraAccount(
      { body, language: language.value },
      {
        onSuccess: () => {
          router.push({ name: 'settings-payment-methods', query: { editPayment: true } })
        },
        onError: response => {
          sendErrorMetrics({
            itemId: selectedAccommodationId.value,
            productName: zuoraMetricConstants.productName.settings,
            code: zuoraMetricConstants.metricErrorCodes.billingInformation,
            message: response.response.data?.message,
          })
          forceRender()
          displayNotification({
            message: 'subscriptions.details.transactionHistory.error',
            isTranslationKey: true,
            type: 'error',
          })
        },
      }
    )
  }
}
</script>
<style scoped>
.sticky {
  position: sticky;
  top: 120px;
  right: 0;
}
</style>
