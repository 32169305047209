<template>
  <div class="tw-bg-gray-100 tw-flex tw-w-full tw-py-12">
    <div
      class="tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-mx-auto tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-px-4"
    >
      <div class="tw-hidden md:tw-flex tw-w-1/3">
        <img
          src="@/assets/img/studio-plus/studio-plus-compare-banner.svg"
          :alt="$t('plus_product_page_benefit_header')"
          width="314"
          class="tw-w-[314px] tw-ml-auto"
        />
      </div>
      <div class="tw-mx-auto">
        <h3 class="tw-font-bold tw-text-2xl tw-text-center md:tw-text-left">
          {{ $t('plus_product_page_benefit_header') }}
        </h3>
        <img
          src="@/assets/img/studio-plus/studio-plus-compare-banner.svg"
          :alt="$t('plus_product_page_benefit_header')"
          width="314"
          class="md:tw-hidden tw-mx-auto tw-my-9"
        />
        <ul class="tw-my-8">
          <li v-for="(item, i) in list" :key="i" class="tw-flex tw-items-center tw-my-4 tw-text-xl tw-leading-[30px]">
            <CheckMark class="tw-mr-4 tw-h-[20px] tw-w-[20px] tw-p-[4px] tw-rounded-full" />
            {{ $t(item) }}
          </li>
        </ul>
        <div class="tw-flex tw-gap-4 md:tw-flex-row tw-flex-col">
          <MainButton @click="upgradeSub" :disabled="!isAdmin">{{ $t('plus_product_page_cta') }}</MainButton>
          <GhostButton @click="redirectToContact">{{ $t('plus_product_page_contact_cta') }}</GhostButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import CheckMark from '@/components/icons/CheckMark.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'

import appAnalytics from '@/utils/tracking'
import { getSupportCenterUrl } from '@/utils/Utility'
import { selectedAccommodationId } from '@/layouts/queries'

const emit = defineEmits(['upgradeSub'])
const upgradeSub = () => {
  appAnalytics.track(appAnalytics.events.SP_CLICK_UPGRADE_PAIN_POINTS, {
    accommodationId: selectedAccommodationId.value,
  })
  emit('upgradeSub')
}

const store = useStore()
const isAdmin = computed(() => store.state.session?.userRole === 'admin')
const selectedLanguage = computed(() => store.state.locale.language)

const supportCenterUrl = computed(() => getSupportCenterUrl(selectedLanguage.value, 'contactUs'))
const redirectToContact = () => {
  return window.open(supportCenterUrl.value, '_blank')
}
const list = ['plus_product_page_benefit_1', 'plus_product_page_benefit_2', 'plus_product_page_benefit_3']
</script>
