export const SUPPORTED_CURRENCIES = [
  {
    code: 'AUD',
    name: 'Australian Dollar',
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
  },
  {
    code: 'GBP',
    name: 'British Pound',
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
  },
  {
    code: 'EUR',
    name: 'Euro',
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
  },
  {
    code: 'USD',
    name: 'US Dollar',
  },
]
