<template>
  <section>
    <div class="md:tw-max-w-screen-lg lg:tw-max-w-screen-xl md:tw-px-4 tw-mx-auto tw-my-12">
      <div class="tw-flex tw-flex-col tw-px-4">
        <h2 class="tw-font-bold tw-text-2xl tw-mb-12 tw-text-center">{{ $t('navigation_menu_support_2') }}</h2>
        <div class="tw-w-full tw-flex tw-flex-wrap">
          <div v-for="(question, index) in faq" :key="index" class="faq tw-flex tw-items-center tw-relative">
            <span class="tw-flex tw-w-full" v-html="$t(question)" />
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              class="tw-bg-blue-700 tw-fill-white tw-rounded-full tw-h-[29px] tw-w-[29px] tw-absolute tw-right-5"
            >
              <ChevronRight />
            </BaseIcon>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

const faq = [
  'studio_plus_mp_faq_1',
  'studio_plus_mp_faq_2',
  'studio_plus_mp_faq_3',
  'studio_plus_mp_faq_4',
  'studio_plus_mp_faq_5',
  'studio_plus_mp_faq_6',
]
</script>
<style scoped>
.faq {
  flex: 50% 0 0;
  @apply tw-border-b tw-border-gray-200 [&:nth-child(5)]:tw-border-0 [&:nth-child(6)]:tw-border-0;
  @media (max-width: theme('screens.lg')) {
    flex: 100% 0 0;
    border: 0;
  }
}

.faq:nth-child(5) {
  @media (max-width: theme('screens.lg')) {
    @apply tw-border-b tw-border-gray-200;
  }
}
.faq :deep(a) {
  @apply tw-flex tw-w-full tw-p-4  tw-justify-between tw-items-center tw-z-2 tw-pr-16;
}

.faq :deep(a):hover {
  @apply tw-text-blue-800;
}

.faq:hover svg {
  @apply tw-bg-blue-800;
}

.faq:nth-child(odd) {
  @media (max-width: theme('screens.lg')) {
    margin-right: 0;
  }
}
</style>
