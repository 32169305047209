import { computed, ref } from 'vue'

import { formatYYYYMMDD } from '@/utils/Utility.js'
import { inferCampaignAggregateType } from '@/utils/rateConnectUtils.js'

const selectedPeriod = ref('last_30_days')
const selectedMarket = ref('all')
const selectedTab = ref('visits')
const selectedDisplayType = ref('table')
const selectedAggregation = ref('day')

export function useRateConnectPerformance() {
  const resetData = () => {
    selectedPeriod.value = 'last_30_days'
    selectedMarket.value = 'all'
    selectedTab.value = 'visits'
    selectedDisplayType.value = 'table'
    selectedAggregation.value = 'day'
  }

  const endDate = computed(() => {
    let newEndDate = new Date()
    newEndDate.setDate(newEndDate.getDate())
    return newEndDate
  })
  const startDate = computed(() => {
    if (!endDate.value) return null
    let newStartDate = new Date()
    switch (selectedPeriod.value) {
      case 'last_14_days':
        newStartDate.setDate(endDate.value.getDate() - 14)
        break
      case 'current_month':
        newStartDate = new Date(endDate.value.getFullYear(), endDate.value.getMonth(), 1)
        break

      case 'last_30_days': {
        newStartDate.setDate(endDate.value.getDate() - 30)
        break
      }
      case 'last_3_months':
        newStartDate.setDate(endDate.value.getDate() - 90)
        break
      case 'last_6_months':
        newStartDate.setDate(endDate.value.getDate() - 180)
        break
      case 'last_12_months':
        newStartDate.setDate(endDate.value.getDate() - 365)
        break
      default:
        newStartDate.setDate(endDate.value.getDate() - 14)
        break
    }
    return newStartDate
  })
  const formattedEndDate = computed(() => (endDate.value ? formatYYYYMMDD(endDate.value, '-') : ''))
  const formattedStartDate = computed(() => (startDate.value ? formatYYYYMMDD(startDate.value, '-') : ''))

  return {
    resetData,
    selectedPeriod,
    selectedMarket,
    startDate,
    endDate,
    formattedStartDate,
    formattedEndDate,
    selectedTab,
    selectedDisplayType,
    selectedAggregation,
  }
}

export function usePrettyStats(statsData, lastUpdated, language) {
  function checkDate(date) {
    return date.startDate <= lastUpdated?.value
  }
  const DateTimeFormat = computed(() => {
    let options = {}
    switch (selectedAggregation.value) {
      case 'day':
        options = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }
        break
      case 'week':
        options = {
          day: '2-digit',
          month: 'short',
        }
        break
      case 'month':
        options = {
          month: 'short',
          year: 'numeric',
        }
        break
      default:
      // do nothing
    }
    return new Intl.DateTimeFormat(language.value, options)
  })
  const campaignTypes = computed(() => statsData.value?.map(campaign => inferCampaignAggregateType(campaign)))
  const categories = computed(() => {
    if (!statsData.value) return []
    return statsData.value.map(d => {
      // we always get startDate & endDate
      // but for showing the day aggregation we can pick either startDate or endDate
      if (selectedAggregation.value === 'day' || selectedAggregation.value === 'month') {
        let startDate = new Date(d.startDate)
        return DateTimeFormat.value?.format(startDate)
      } else {
        // week
        let startDate = new Date(d.startDate)
        let endDate = new Date(d.endDate)
        return DateTimeFormat.value?.format(startDate) + ' - ' + DateTimeFormat.value?.format(endDate)
      }
    })
  })
  const tickPositions = computed(() => {
    return statsData.value?.map((d, index) => {
      if (selectedAggregation.value === 'day') {
        return new Date(d.startDate).getDay() === 1 ? index : undefined
      }
      return index
    })
  })

  const bookings = computed(() => {
    const bookingsNames = ['partnerClicks', 'bookings', 'click2BookRatio', 'cancelledBookings']
    let bookingsObj = {}
    bookingsNames.forEach(name => {
      switch (name) {
        case 'click2BookRatio':
          bookingsObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        default:
          bookingsObj[name] = statsData.value?.filter(checkDate).map(d => {
            return d[name] || 0
          })
      }
    })
    return bookingsObj
  })

  const visits = computed(() => {
    const visitsNames = ['totalImpressions', 'partnerClicks', 'cpc', 'clickThroughRate', 'unavailabilityScore']
    let visitsObj = {}
    visitsNames.forEach(name => {
      switch (name) {
        case 'clickThroughRate':
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        case 'cpc':
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => (d[name] === null ? null : d[name] / 100))
          break
        case 'unavailabilityScore':
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => (d[name] === null ? -0.1 : d[name]))
          break
        default:
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return visitsObj
  })

  const revenue = computed(() => {
    const revenueNames = ['bookings', 'bookingRevenue', 'avgBookingRevenue', 'bookingRefundedRevenue']
    let revenueObj = {}
    revenueNames.forEach(name => {
      switch (name) {
        case 'bookingRevenue':
        case 'avgBookingRevenue':
          revenueObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        case 'bookingRefundedRevenue':
          revenueObj[name] = statsData.value?.filter(checkDate).map(d => (d[name] ? d[name] / 100 : 0))
          break
        default:
          revenueObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return revenueObj
  })

  const cpa = computed(() => {
    const cpaNames = ['costs', 'bookingRevenue', 'cpa']
    let cpaObj = {}
    cpaNames.forEach(name => {
      switch (name) {
        case 'cpa':
        case 'costs':
        case 'bookingRevenue':
          cpaObj[name] = statsData.value?.filter(checkDate).map(d => Number(Math.round(d[name] / 100 + 'e2') + 'e-2'))
          break
        default:
          cpaObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return cpaObj
  })

  const commissionCosts = computed(() => {
    const commissionCostNames = ['commissionCost', 'bookingRevenue', 'cpaLevel']
    const commissionCostsObj = {}
    commissionCostNames.forEach(name => {
      switch (name) {
        case 'commissionCost':
        case 'bookingRevenue':
          commissionCostsObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        case 'cpaLevel':
          commissionCostsObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
          break
        default:
          commissionCostsObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return commissionCostsObj
  })

  return {
    bookings,
    visits,
    revenue,
    cpa,
    commissionCosts,
    campaignTypes,
    categories,
    tickPositions,
  }
}
