<template>
  <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
    <div class="tw-w-full tw-justify-between tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
      <div>
        <p class="tw-text-xl tw-font-bold">{{ $t('app_images_header_title') }}</p>
        <i18n-t
          keypath="app_images_header_text"
          tag="p"
          class="tw-hidden tw-mt-2 md:tw-block tw-text-sm"
          scope="global"
        >
          <template #LearnMore>
            <a
              class="tw-text-blue-700 hover:tw-text-blue-800 tw-text-center tw-cursor-pointer tw-transition-colors hover:tw-no-underline tw-duration-300 tw-text-sm"
              @click="emit('toggleTipsModal')"
              @keydown.enter="emit('toggleTipsModal')"
            >
              {{ $t('app_images_header_learn_more') }}
            </a>
          </template>
        </i18n-t>
      </div>
      <div class="tw-mt-4 md:tw-mt-0 tw-hidden md:tw-flex tw-w-auto tw-items-start tw-whitespace-nowrap">
        <DangerButton :disabled="deleteDisabled" size="medium" class="tw-px-9 tw-mr-2" @click="emit('deleteImages')">{{
          $t('app_images_actions_delete')
        }}</DangerButton>

        <!-- TO BE SWITCHED -->
        <UiTooltip tooltip-position="top" :label="$t('tbs_maintenance_text')" class="tw-cursor-pointer">
          <MainButton size="medium" class="tw-px-5" @click="emit('openAddImageModal')" :disabled="true">{{
            $t('nba_cta_expired_hotelier_images')
          }}</MainButton>
        </UiTooltip>
      </div>
      <i18n-t keypath="app_images_header_text" tag="p" class="md:tw-hidden tw-block tw-text-sm" scope="global">
        <template #LearnMore>
          <a
            class="tw-text-blue-700 hover:tw-text-blue-800 tw-text-center tw-cursor-pointer tw-transition-colors hover:tw-no-underline tw-duration-300 tw-text-sm"
            @click="emit('toggleTipsModal')"
            @keydown.enter="emit('toggleTipsModal')"
          >
            {{ $t('app_images_header_learn_more') }}
          </a>
        </template>
      </i18n-t>
    </div>

    <div class="tw-mt-4 md:tw-mt-0 md:tw-hidden tw-flex tw-justify-end">
      <DangerButton :disabled="deleteDisabled" size="medium" class="tw-px-9 tw-mr-2" @click="emit('deleteImages')">{{
        $t('app_images_actions_delete')
      }}</DangerButton>
      <!-- TO BE SWITCHED -->
      <UiTooltip tooltip-position="top" :label="$t('tbs_maintenance_text')" class="tw-cursor-pointer">
        <MainButton size="medium" class="tw-px-5" @click="emit('openAddImageModal')" :disabled="true">{{
          $t('nba_cta_expired_hotelier_images')
        }}</MainButton>
      </UiTooltip>
    </div>
  </div>
</template>
<script setup>
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'

const emit = defineEmits(['toggleTipsModal', 'deleteImages', 'openAddImageModal'])
defineProps({
  deleteDisabled: Boolean,
})
</script>
