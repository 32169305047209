<template>
  <div>
    <div class="tw-overflow-x-hidden">
      <AuthMenu isLanding @onRegisterClick="onAuthorize('login')" pageName="studio-plus" />
      <ProductPageHero pageName="studio-plus" class="tw-pt-16" @onLoginClick="onAuthorize('login')" />
      <StudioPlusBenefits class="tw-my-14" />
      <StudioPlusApps class="tw-bg-white" />
      <StudioPlusGuide class="tw-bg-white" />
      <ProductPageFaq pageName="studio-plus" class="tw-bg-white" />
    </div>
    <ThePageFooter isLanding />
    <TheCookieNotice />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import ProductPageHero from '@/components/productPage/ProductPageHero.vue'
import StudioPlusBenefits from '@/components/productPage/studioPlus/StudioPlusBenefits.vue'
import ProductPageFaq from '@/components/productPage/ProductPageFaq.vue'

import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import StudioPlusApps from '@/components/productPage/studioPlus/StudioPlusApps.vue'
import StudioPlusGuide from '@/components/productPage/studioPlus/StudioPlusGuide.vue'

const store = useStore()
const route = useRoute()

const windowWidth = ref(window.innerWidth)
const locale = computed(() => store.state.locale.language)

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    locale: locale.value,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
  })
}
</script>
