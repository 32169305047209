<template>
  <BaseCard
    v-if="slideCount > 0"
    noPadding
    class="carousel tw-relative tw-max-w-screen-xxl tw-mx-auto tw-mb-8 tw-flex tw-flex-col tw-w-full"
    :class="slideCount > 1 ? 'tw-pt-16 tw-pb-8' : 'tw-py-8'"
  >
    <div class="tw-absolute tw-z-20 tw-justify-end tw-items-start tw-flex tw-top-9" v-if="slideCount > 1">
      <ul class="tw-flex tw-flex-row tw-h-full tw-grow tw-justify-start tw-pl-5 lg:tw-pl-9">
        <li v-for="i in slideCount" :key="i" class="tw-flex tw-justify-center tw-pb-4 tw-items-center tw-mr-2.5">
          <button
            :class="selectedSlide === i ? 'tw-bg-blue-700' : 'tw-bg-gray-500 tw-border-gray-500'"
            class="tw-text-base md:tw-text-xl tw-rounded-full tw-w-2.5 tw-h-2.5 md:tw-w-2.5 md:tw-h-2.5 tw-outline-none focus:tw-outline-none tw-relative tw-transition-all tw-duration-300 tw-ease-in-out"
            @click="onCarouselNavClick(i)"
            @keydown.enter="onCarouselNavClick(i)"
          ></button>
        </li>
      </ul>
    </div>
    <div
      class="tw-px-5 lg:tw-px-0"
      :class="slideCount > 1 ? 'tw-h-[500px] md:tw-h-[350px] tw-flex tw-w-full tw-items-center tw-justify-between' : ''"
    >
      <div v-if="isLoading" class="tw-mx-8">
        <div
          class="tw-animate-pulse tw-rounded tw-bg-gray-300 tw-mb-8 tw-h-[400px] md:tw-h-[300px] tw-w-full"
          aria-hidden="true"
        />
      </div>
      <div v-if="!isLoading && selectedSlideName === 'rate-connect-upsell'" class="tw-w-full">
        <RateConnectUpsellBanner />
      </div>
      <div v-if="!isLoading && selectedSlideName === 'rate-connect-reactivate'" class="tw-w-full">
        <RateConnectResumeBanner />
      </div>
      <div v-if="!isLoading && selectedSlideName === 'studio-plus-upsell'" class="tw-w-full md:tw-h-auto tw-h-full">
        <StudioPlusUpsellBanner />
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import RateConnectUpsellBanner from '@/components/dashboard/rateConnectUpsell/RateConnectUpsellBanner.vue'
import RateConnectResumeBanner from '@/components/dashboard/rateConnectUpsell/RateConnectResumeBanner.vue'
import StudioPlusUpsellBanner from '@/components/dashboard/studioPlusUpsell/StudioPlusUpsellBanner.vue'

const props = defineProps({
  isRCUpsell: Boolean,
  isRCResume: Boolean,
  isPlusCancelled: Boolean,
  isStudioPlus: Boolean,
  isLoading: Boolean,
})

const slides = computed(() => {
  if (props.isRCUpsell && !props.isStudioPlus) {
    return [{ name: 'rate-connect-upsell' }, { name: 'studio-plus-upsell' }]
  } else if (props.isRCResume && !props.isStudioPlus) {
    return [{ name: 'rate-connect-reactivate' }, { name: 'studio-plus-upsell' }]
  } else if (!props.isRCUpsell && !props.isRCResume && !props.isStudioPlus) {
    return [{ name: 'studio-plus-upsell' }]
  } else if (props.isRCUpsell && props.isStudioPlus) {
    return [{ name: 'rate-connect-upsell' }]
  } else if (props.isRCResume && props.isStudioPlus) {
    return [{ name: 'rate-connect-reactivate' }]
  } else if (props.isPlusCancelled || props.isStudioPlus) {
    return []
  } else {
    return []
  }
})
const timer = ref('')
const slideCount = computed(() => slides.value.length)
const selectedSlide = ref(0)
const selectedSlideName = computed(() => slides.value[selectedSlide.value - 1]?.name)

onMounted(() => {
  autoplay()
})

onUnmounted(() => {
  clearTimeout(timer.value)
})

const autoplay = () => {
  if (selectedSlide.value < slideCount.value) {
    selectedSlide.value++
  } else {
    selectedSlide.value = 1
  }
  timer.value = setTimeout(autoplay, 10000)
}

const onCarouselNavClick = value => {
  clearTimeout(timer.value)
  autoplay()
  updateSelectedSlide(value)
}

const updateSelectedSlide = value => {
  selectedSlide.value = value
}
</script>
<style scoped>
.carousel {
  overflow: hidden;

  @media (min-width: theme('screens.md')) {
    max-height: 500px;
  }

  @media (max-width: theme('screens.md')) {
    background: theme('colors.white');
  }
}
.carousel-inner {
  @media (max-width: theme('screens.sm')) {
    min-height: 286px;
  }
}
.carousel-icon {
  max-width: 300px;
  margin: 0 auto;

  @media (min-width: theme('screens.md')) {
    margin: auto;
    max-width: 300px;
  }

  @media (min-width: theme('screens.lg')) {
    max-width: 530px;
  }
}
</style>
