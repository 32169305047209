<template>
  <div class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto">
    <BaseCard no-padding class="card">
      <div class="tw-p-6 tw-pb-8">
        <div class="tw-text-gray-800">
          <h2 class="tw-text-xl tw-font-bold tw-pb-2.5">{{ $t('rc_bidding_head') }}</h2>
          <p class="tw-w-full md:tw-w-3/5 tw-font-normal tw-text-sm">{{ $t('rc_bidding_head_subtext') }}</p>
        </div>
        <BiddingModeGraph
          :bidding-modes="biddingModes"
          :selectedCPCLevel="selectedCPCLevel"
          @onNewModeSelected="onNewModeSelected"
        />
        <BiddingModeDescription :bidding-modes="biddingModes" :selectedCPCLevel="selectedCPCLevel" />
      </div>
      <div
        class="tw-flex tw-border-t tw-border-gray-300 tw-px-6 tw-py-8 md:tw-flex-row tw-flex-col"
        :class="isSelectedCPCDifferentFromCurrent ? 'tw-justify-between' : 'tw-justify-end'"
      >
        <GhostButton
          v-show="isSelectedCPCDifferentFromCurrent"
          class="tw-px-9 tw-order-2 md:tw-order-1 tw-mb-4 md:tw-mb-0"
          size="medium"
          @click="resetSelection"
          >{{ $t('cancel') }}</GhostButton
        >
        <div
          v-show="isSelectedCPCDifferentFromCurrent"
          class="tw-flex tw-justify-end tw-order-1 md:tw-order-2 tw-mb-4 md:tw-mb-0 tw-mx-2"
        >
          <p class="tw-text-sm tw-text-center md:tw-text-right tw-w-full md:tw-w-[60%] tw-leading-[18px]">
            {{ $t('rc_bidding_change_effect') }}
          </p>
        </div>
        <MainButton
          class="tw-px-9 tw-order-3"
          size="medium"
          :disabled="!isSelectedCPCDifferentFromCurrent"
          @click="submitNewBiddingGoal"
        >
          <span>{{ $t('save') }}</span>
        </MainButton>
      </div>
    </BaseCard>
    <FullScreenLoader v-show="isUpdatingCampaign" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BiddingModeGraph from '@/components/rateConnect/biddingGoals/BiddingModeGraph.vue'
import BiddingModeDescription from '@/components/rateConnect/biddingGoals/BiddingModeDescription.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useCampaigns, useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { displayNotification } = useToastNotifications()
const { campaignsData } = useCampaigns(selectedAccommodationId)
const { updateCampaign, isUpdatingCampaign } = useCampaignsMutation()

const biddingModes = ['low', 'auto', 'high', 'boost']
const newCPCLevel = ref(null)

const campaignCurrentCPCLevel = computed(() => campaignsData.value?.campaigns?.[0]?.cpcLevel)
const selectedCPCLevel = computed(() => newCPCLevel.value ?? campaignCurrentCPCLevel.value)
const isSelectedCPCDifferentFromCurrent = computed(() => newCPCLevel.value !== campaignCurrentCPCLevel.value)

const resetSelection = () => {
  newCPCLevel.value = campaignCurrentCPCLevel.value
}
const onNewModeSelected = newVal => (newCPCLevel.value = newVal)

const submitNewBiddingGoal = () => {
  const currentCampaigns = campaignsData.value?.campaigns
  if (!currentCampaigns || !Array.isArray(currentCampaigns) || currentCampaigns.length < 1) return
  const campaignsWithUpdatedCPCLevel = currentCampaigns.map(campaign => ({
    marketCode: campaign.marketCode,
    cpcLevel: selectedCPCLevel.value,
  }))

  updateCampaign(
    {
      accommodationId: selectedAccommodationId.value,
      body: { campaigns: campaignsWithUpdatedCPCLevel },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'rc_bidding_change_success',
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
<style scoped>
.card {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
  @apply tw-bg-white;
}
</style>
