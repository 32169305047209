<template>
  <section class="tw-pb-14">
    <div
      class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-w-full tw-flex-col"
    >
      <div class="tw-flex tw-flex-col">
        <h2 class="tw-font-bold tw-text-2xl tw-mb-8 tw-text-center sm:tw-text-left">{{ $t('tbs_mp_faq_header') }}</h2>
        <div class="tw-w-full tw-flex tw-flex-wrap tw-rounded-lg tw-border tw-border-gray-300">
          <div v-for="(question, index) in faq" :key="index" class="faq tw-flex tw-items-center tw-relative">
            <span class="tw-flex tw-w-full" v-html="$t(question)" />
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              class="tw-bg-blue-700 tw-fill-white tw-rounded-full tw-h-[29px] tw-w-[29px] tw-absolute tw-right-5"
            >
              <ChevronRight />
            </BaseIcon>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

const props = defineProps({
  pageName: String,
})

const faq = computed(() => {
  if (props.pageName === 'rate-connect') {
    return ['rc_mp_faq_1', 'rc_mp_faq_2', 'rc_mp_faq_3', 'rc_mp_faq_4', 'rc_mp_faq_5', 'rc_mp_faq_6']
  } else if (props.pageName === 'studio-plus') {
    return [
      'studio_plus_mp_faq_1',
      'studio_plus_mp_faq_2',
      'studio_plus_mp_faq_3',
      'studio_plus_mp_faq_4',
      'studio_plus_mp_faq_5',
      'studio_plus_mp_faq_6',
    ]
  } else if (props.pageName === 'free-booking-links') {
    return ['lp_free_link_faq_1', 'lp_free_link_faq_2', 'lp_free_link_faq_3', 'lp_free_link_faq_4']
  } else return []
})
</script>
<style scoped>
.faq {
  flex: 50% 0 0;
  @apply tw-border-b tw-border-gray-300 [&:nth-child(5)]:tw-border-0 [&:nth-child(6)]:tw-border-0;
  @media (max-width: theme('screens.lg')) {
    flex: 100% 0 0;
  }
}

.faq:nth-child(5) {
  @media (max-width: theme('screens.lg')) {
    @apply tw-border-b tw-border-gray-300;
  }
}
.faq :deep(a) {
  @apply tw-flex tw-w-full tw-p-4  tw-justify-between tw-items-center tw-z-2 tw-pr-16;
}

.faq :deep(a):hover {
  @apply tw-text-blue-800;
}

.faq:hover svg {
  @apply tw-bg-blue-800;
}

.faq:nth-child(odd) {
  @media (max-width: theme('screens.lg')) {
    margin-right: 0;
  }
}
</style>
