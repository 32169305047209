<template>
  <CustomModal
    class="add-images-modal"
    :is-modal-open="isOpen"
    @toggleModal="() => emit('closeModal')"
    @onClickAway="() => emit('closeModal')"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-[636px] md:tw-w-[636px] tw-h-screen md:tw-h-auto md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('nba_cta_expired_hotelier_images') }}</p>
    </template>

    <div class="tw-flex tw-flex-col tw-mb-4">
      <div class="tw-flex tw-items-center tw-my-4 tw-flex-col">
        <p class="tw-flex tw-items-center tw-font-bold tw-text-xl tw-my-4">{{ $t('app_images_header_title') }}</p>
        <i18n-t
          keypath="app_images_header_text"
          tag="p"
          class="tw-text-sm tw-inline-block tw-text-center"
          scope="global"
        >
          <template #LearnMore>
            <a
              class="tw-text-blue-700 hover:tw-text-blue-800 tw-text-center tw-cursor-pointer tw-transition-colors hover:tw-no-underline tw-duration-300 tw-text-sm"
              @click="toggleTips()"
              @keydown.enter="toggleTips()"
            >
              {{ $t('app_images_header_learn_more') }}
            </a>
          </template>
        </i18n-t>

        <DropzoneUpload
          :files="files"
          :is-dragging="isDragging"
          @onchange="onFilesChange"
          @dragover="dragover"
          @drop="drop"
          @dragleave="dragleave"
          @remove="remove"
        />
      </div>
      <TipsContent v-if="tipsOpened" />
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end tw-gap-4">
        <GhostButton size="medium" class="tw-px-9" @click="emit('closeModal')">{{ $t('close') }}</GhostButton>
        <MainButton size="medium" class="tw-px-9" :disabled="files.length === 0" @click="saveNewImages">
          {{ $t('nba_cta_expired_hotelier_images') }}
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'

import CustomModal from '@/components/base/CustomModal.vue'
import TipsContent from '@/components/propertyDetails/propertyImages/TipsContent.vue'
import DropzoneUpload from '@/components/propertyDetails/propertyImages/DropzoneUpload.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { generateGUID } from '@/utils/propertyDetailsHelpers.js'
import { usePropertyImages } from '@/components/propertyDetails/propertyImages/usePropertyImages.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import AccommodationService from '@/services/AccommodationService'
import { selectedAccommodationId } from '@/layouts/queries'

const { isUploading, setUploadingImages, removeUploadingImages, setUploading } = usePropertyImages()

const queryClient = useQueryClient()
const emit = defineEmits(['closeModal', 'openModal'])
const openModal = () => emit('openModal')
defineProps({
  isOpen: Boolean,
})
const tipsOpened = ref(false)
const isDragging = ref(false)
const files = ref([])
const uploadSucceed = ref(0)
const uploadFailed = ref([])

const { displayNotification } = useToastNotifications()

watch(isUploading, (newValue, oldValue) => {
  if (newValue && !oldValue && uploadFailed.value.length === 0) {
    files.value = []
  }
})

const toggleTips = () => {
  tipsOpened.value = !tipsOpened.value
}
const onFilesChange = e => {
  e.preventDefault()
  files.value = [...e.target.files]
}
const dragover = e => {
  e.preventDefault()
  isDragging.value = true
}
const dragleave = () => {
  isDragging.value = false
}
const drop = e => {
  e.preventDefault()
  files.value = [...e.dataTransfer.files]
  isDragging.value = false
}
const remove = (event, i) => {
  files.value.splice(i, 1)
}
const saveErrorCallback = () => {
  uploadFailed.value.push('failed')
}
const saveSuccessCallback = i => {
  uploadSucceed.value += 1
  files.value.splice(i, 1)
}

const saveNewImages = () => {
  const promises = []
  files.value.forEach((file, i) => {
    promises.push(
      new Promise((resolve, reject) => {
        if (file.size > 20000000) {
          // prevent images > 20 MB from being uploaded
          uploadFailed.value.push('max_size')
          files.value.splice(i, 1)
          resolve()
        }
        const imageURL = URL.createObjectURL(file)
        let img = new Image()
        img.src = imageURL
        img.onload = () => {
          setUploading(true)
          if (img.width >= 2000) {
            const fileCopy = {
              id: generateGUID(),
              status: 'UPLOADING',
              name: file.name,
            }
            const body = new window.FormData()
            body.append('image', file)
            setUploadingImages(fileCopy)
            emit('closeModal')
            AccommodationService.uploadImage({ accommodationId: selectedAccommodationId.value, body })
              .then(() => {
                saveSuccessCallback(i)
                resolve()
              })
              .catch(() => {
                saveErrorCallback()
                reject()
              })
          } else {
            uploadFailed.value.push('min_size')
            files.value.splice(i, 1)
            resolve()
          }
        }
      })
    )
  })

  Promise.allSettled(promises).then(() => {
    queryClient.invalidateQueries({ queryKey: ['images'] })
    removeUploadingImages()
    emit('closeModal')
    if (uploadSucceed.value > 0) {
      displayNotification({
        message: uploadSucceed.value > 1 ? 'app_images_uploaded_images' : 'app_images_uploaded_image',
        translationParams: { count: uploadSucceed.value },
        isTranslationKey: true,
        type: 'success',
        isHtml: false,
      })
      uploadSucceed.value = 0
    }
    if (uploadFailed.value.length > 0) {
      if (uploadFailed.value.every(item => item === 'min_size')) {
        displayNotification({
          message:
            uploadFailed.value.length > 1
              ? 'app_images_upload_failed_min_size_multiple'
              : 'app_images_upload_failed_min_size',
          translationParams: { count: uploadSucceed.value },
          isTranslationKey: true,
          type: 'error',
          isHtml: false,
        })
      } else if (uploadFailed.value.every(item => item === 'max_size')) {
        displayNotification({
          message:
            uploadFailed.value.length > 1
              ? 'app_images_upload_failed_max_size_multiple'
              : 'app_images_upload_failed_max_size',
          translationParams: { count: uploadSucceed.value },
          isTranslationKey: true,
          type: 'error',
          isHtml: false,
        })
      } else {
        displayNotification({
          message: uploadFailed.value.length > 1 ? 'app_images_upload_failed_images' : 'app_images_upload_failed_image',
          translationParams: { count: uploadFailed.value.length },
          isTranslationKey: true,
          type: 'error',
          isHtml: false,
          actionButton: true,
          actionLabel: 'dashboard.loadFail.retryButton',
          action: openModal,
        })
      }
      uploadFailed.value = []
      setUploading(false)
    }
  })
}
</script>
