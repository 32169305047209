<template>
  <div
    :class="
      route.name === 'studio-plus-compare'
        ? 'tw-w-full tw-mx-auto'
        : 'tw-my-6 tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mx-auto'
    "
  >
    <LaunchpadLoader v-if="initialDataLoading" class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1" />
    <router-view v-else />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import useRateConnect from '@/components/rateConnect/queries'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const router = useRouter()
const route = useRoute()

const currency = computed(() => store.state.currency)
const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const reactivationSubscriptionType = computed(() => store.state.studioPlus.reactivationSubscriptionType)
const { studioPlusPrice } = useStudioPlusPrice({ selectedAccommodationId, currency })

const userRole = computed(() => store.state.session?.userRole)
const isDeepLink =
  window.localStorage.getItem('fromDeepLink') ||
  (router.currentRoute.value.query.itemID && router.currentRoute.value.query.orgID)
const { isStartUpLoading, isStartUpFetching } = useRateConnect(selectedAccommodationId)
const { isPlus, isLoading, isFetching } = useStudioPlusCampaign(selectedAccommodationId)

const initialDataLoading = computed(() => isLoading.value || isStartUpLoading.value)
const isRequiredDataFetching = computed(() => isFetching.value || isStartUpFetching.value)

const isEligibleForStudioPlus = computed(() => {
  if (initialDataLoading.value) return false
  if (userRole.value !== 'admin') return false
  if (studioPlusPrice.value) return false
  if (isPlus.value) return false
  return true
})

watch(isRequiredDataFetching, newVal => {
  if (isDeepLink && userRole.value !== 'admin') {
    window.localStorage.removeItem('fromDeepLink')
    router.replace({ name: 'not-admin-error' })
  } else if (newVal === false && isEligibleForStudioPlus.value === false) {
    if (reactivationAccommodationId.value === null && reactivationSubscriptionType.value === null) {
      router.replace('/')
    }
  }
})

watch(selectedAccommodationId, () => {
  router.replace('/')
})
</script>
