import axios from 'axios'

import BaseService from './BaseService.js'

class OrganisationManagementService extends BaseService {
  constructor() {
    super({ baseUrl: import.meta.env.VITE_ORGANISATION_MANAGEMENT_API_URL })
  }

  getOrganisationsList() {
    if (!this.JLoToken) throw new Error('Error in GET organisations: No JLoToken provided')
    return axios({
      url: `${this.baseUrl}/organisations`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: this.JLoToken,
      },
    })
  }

  postGenerateToken({ organisationId = '' }) {
    if (!this.JLoToken) throw new Error('Error in POST generate_token: No JLoToken provided')
    if (!organisationId) throw new Error('Error in POST generate_token: No organisationId provided')
    return axios({
      url: `${this.baseUrl}/auth/generate_token`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: this.JLoToken,
      },
      data: {
        organisationId,
      },
    })
  }

  getUserDetails() {
    if (!this.JLoToken) throw new Error('Error in GET users: No JLoToken provided')
    return axios({
      url: `${this.baseUrl}/users`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: this.JLoToken,
      },
    })
  }

  getOrganisationAssignments({ organisationId }) {
    if (!this.studioToken) throw new Error('Error in GET organisations/{orgId}/assignments: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/organisations/${organisationId}/assignments?pageSize=${10000}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        organisationId,
      },
    })
  }

  postOrganisationAssignments({ accommodationId, isOnboarding }) {
    return axios({
      url: isOnboarding
        ? `${this.baseUrl}/organisations/assignments?isOnboarding=true`
        : `${this.baseUrl}/organisations/assignments`,
      method: 'POST',
      data: { accommodationId },
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getOrganisationUsers({ organisationId } = {}) {
    if (!this.JLoToken) throw new Error('Error in POST /organisations/${organisationId}/users No JLoToken, provided')
    return axios({
      url: `${this.baseUrl}/organisations/${organisationId}/users`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  inviteUser({ data }) {
    if (!this.studioToken) throw new Error('Error in POST /organisations/users No studioToken provided')
    return axios({
      url: `${this.baseUrl}/organisations/users`,
      method: 'POST',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getUserInvitations() {
    if (!this.JLoToken) throw new Error('Error in GET /organisations/invitations No JLoToken, provided')
    return axios({
      url: `${this.baseUrl}/organisations/invitations`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  removeUserFromOrganisation({ organisationId }) {
    if (!this.studioToken) throw new Error('Error in DELETE /organisations/{orgId}/users No studioToken provided')
    if (!organisationId) throw new Error('Error in DELETE /organisations/{orgId}/users No organisationId provided')
    return axios({
      url: `${this.baseUrl}/organisations/${organisationId}/users`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  removeUserInvitationFromOrganisation(email) {
    if (!this.studioToken) throw new Error('Error in DELETE /organisations/users No studioToken provided')

    return axios({
      url: `${this.baseUrl}/organisations/users`,
      data: email,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  removeExistingUserFromOrganisation({ userId }) {
    if (!this.studioToken) throw new Error('Error in DELETE /organisations/users/{userId} No studioToken provided')
    if (!userId) throw new Error('Error in DELETE /organisations/users/{userId} No userId provided')
    return axios({
      url: `${this.baseUrl}/organisations/users/${userId}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateUserRoleInOrganisation({ userId, role } = {}) {
    if (!this.studioToken)
      throw new Error('Error in POST /organisations/${organisationId}/users/${userId}/roles No studioToken provided')
    return axios({
      url: `${this.baseUrl}/organisations/users/${userId}/roles`,
      method: 'PUT',
      data: { role },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  validatePhoneNumber(body) {
    return axios({
      url: `${this.baseUrl}/validation/phone_number`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  updateUserProfile({ userId, body }) {
    if (!this.studioToken) throw new Error('Error in PUT /user/${userId} No studioToken provided')
    return axios({
      url: `${this.baseUrl}/users/${userId}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  createUserProfile({ body }) {
    if (!this.JLoToken) throw new Error('Error in POST /users No JLoToken provided')
    return axios({
      url: `${this.baseUrl}/users`,
      method: 'POST',
      data: body,
      headers: {
        Authorization: this.JLoToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  updateOrganisationName(body) {
    if (!this.studioToken) throw new Error('Error in PUT /organisations No studioToken provided')
    return axios({
      url: `${this.baseUrl}/organisations`,
      method: 'PUT',
      data: body,
      headers: {
        'X-Studio-Authorization': this.studioToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }
}

export default new OrganisationManagementService()
