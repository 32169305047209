<template>
  <div class="tw-mb-6" v-if="!loading">
    <div class="tw-flex tw-items-start tw-flex-col tw-border-b tw-mb-6 tw-pb-5 tw-border-gray-300">
      <img :src="businessPlusLogo" :alt="$t('studio_plus_mp_bs_plus_features_header')" class="tw-h-[23px] tw-mb-6" />

      <div>
        <p class="tw-font-light current-price">
          <span> {{ Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(price / 100) }}</span>
        </p>
      </div>
      <p class="tw-text-gray-800">
        {{ $t('check_out_billing_info_subscription') }}
      </p>

      <div class="tw-mt-6">
        <li v-for="(feature, i) in featuresList" :key="i" class="tw-flex tw-justify-start tw-items-center tw-mb-2.5">
          <BaseIcon height="9" width="12">
            <Tick class="tw-fill-green-800 tw-h-[9px] tw-w-[12px]" />
          </BaseIcon>
          <span class="tw-ml-3">{{ $t(feature) }}</span>
        </li>
      </div>
    </div>

    <div class="tw-flex tw-justify-between tw-mb-2 tw-text-sm">
      <p>{{ $t('business_studio_plus_price') }}</p>
      <span>
        {{ Intl.NumberFormat(language, { style: 'currency', currency: currency }).format((price * 12) / 100) }}</span
      >
    </div>
    <div class="tw-flex tw-justify-between tw-text-sm">
      <p>{{ $t('check_out_page_VAT') }}</p>
      <span>{{
        Intl.NumberFormat(language, { style: 'currency', currency: currency }).format((priceTaxAmount * 12) / 100)
      }}</span>
    </div>
    <div class="tw-flex tw-justify-between tw-font-bold tw-mt-6 tw-border-b tw-mb-6 tw-pb-5 tw-border-gray-300">
      <p>{{ $t('business_studio_payment_due') }}</p>

      <span>{{
        Intl.NumberFormat(language, { style: 'currency', currency: currency }).format((priceWithTax * 12) / 100)
      }}</span>
    </div>
  </div>
  <div v-else class="tw-mb-6">
    <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[221px] tw-w-full tw-mb-4" aria-hidden="true" />
    <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full tw-mb-4" aria-hidden="true" />
    <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full tw-mb-4" aria-hidden="true" />
    <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full" aria-hidden="true" />
    <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full" aria-hidden="true" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Tick from '@/components/icons/Tick.vue'
import BaseIcon from '@/components/BaseIcon.vue'

const businessPlusLogo = new URL('/src/assets/img/logos/business-plus-logo.svg', import.meta.url)

const store = useStore()
const props = defineProps({
  price: Object,
  loading: Boolean,
})

const language = computed(() => store.state.locale.language)
const currency = computed(() => store.state.currency)

const featuresList = [
  'check_out_feature_1',
  'check_out_feature_2',
  'check_out_feature_3',
  'check_out_feature_4',
  'check_out_feature_5',
  'check_out_feature_6',
  'check_out_feature_7',
]

const price = computed(() => props.price?.priceBeforeTaxes ?? 0)

const priceTaxAmount = computed(() => props.price?.taxAmount ?? 0)

const priceWithTax = computed(() => props.price?.price ?? 0)
</script>

<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
</style>
