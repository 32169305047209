<template>
  <h3 class="tw-font-bold tw-mb-1">{{ $t('studio_plus_prop_overview_checkout') }}</h3>
  <BaseCard noPadding class="tw-px-4 tw-p-6">
    <div class="tw-flex tw-items-center">
      <AccommodationImage
        :image="currentAccommodation.mainImageUrls"
        class="tw-min-h-full tw-h-[32px] tw-w-[32px] tw-shrink-0 tw-mr-4"
      />
      <div class="tw-flex tw-items-start tw-flex-col tw-justify-start tw-grow tw-truncate">
        <p class="tw-font-bold tw-truncate tw-w-full">{{ currentAccommodation.name }}</p>
        <p class="tw-text-xxs tw-w-full tw-truncate tw-text-gray-700">
          <strong>ID:</strong> <span>{{ currentAccommodation.accommodationId }}</span>
        </p>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import AccommodationImage from '@/components/dashboard/accommodation/AccommodationImage.vue'

import { useAccommodationsByOrgId } from '@/layouts/queries'

const props = defineProps({
  accommodationId: String,
})
const store = useStore()

const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === props.accommodationId)
)
</script>
