<template>
  <BaseCard>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-800">
      <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
        <div>
          <p class="tw-text-xl tw-font-bold">{{ $t('business_studio_plus_checkout_org_header') }}</p>
        </div>
        <div
          class="tw-flex tw-flex-col tw-justify-between md:tw-flex-row md:tw-items-start"
          :class="isEditing ? 'tw-my-4' : 'tw-mt-4'"
        >
          <div class="tw-flex tw-items-center md:tw-w-auto tw-w-full">
            <BaseIcon :height="18" :width="20" class="tw-mr-4 tw-fill-gray-800">
              <NetworkIcon />
            </BaseIcon>
            <div class="tw-flex tw-flex-col">
              <p v-if="!isEditing" class="tw-font-bold tw-text-sm">{{ orgName }}</p>

              <BaseTextField
                v-else
                size="medium"
                class="md:tw-w-[360px] tw-w-full md:tw-mr-2 tw-mb-1 md:tw-mb-auto"
                :value="orgName"
                @onInput="e => setNewOrgName(e)"
                @onChange="trimOrgName"
                @onBlur="trimOrgName"
              />
              <p class="tw-text-xs tw-text-gray-600" :class="{ 'tw-mt-1': isEditing }">
                {{ $t('organization_id') }}
                {{ ` ${currentOrganisation.id}` }}
              </p>
            </div>
          </div>
          <div v-if="!isEditing">
            <GhostButton size="medium" class="tw-w-full lg:tw-w-auto tw-mt-2 md:tw-mt-0" @click="setEditMode">
              <span v-if="changeOrganisationNameLoading"><RCInlineLoader color="#fff" /></span>
              <span v-else>{{ $t('organization_name_change_cta') }}</span>
            </GhostButton>
          </div>
          <div v-else>
            <GhostButton size="medium" class="tw-w-full lg:tw-w-auto tw-mr-4 tw-mt-2 md:tw-mt-0" @click="resetState">
              <span>{{ $t('cancel') }}</span>
            </GhostButton>
            <MainButton
              size="medium"
              class="tw-w-full lg:tw-w-auto tw-mt-2 md:tw-mt-0"
              @click="saveNewOrgName"
              :disabled="orgName.length < 5"
            >
              <span>{{ $t('organization_name_change_cta') }}</span>
            </MainButton>
          </div>
        </div>
        <div v-if="isEditing">
          <div class="tw-flex tw-items-start tw-w-full md:tw-items-center tw-mb-2">
            <BaseIcon
              icon-name="information-outline"
              class="tw-fill-none tw-mr-1 tw-mt-[2px]"
              :class="orgName.length < 5 ? 'tw-stroke-gray-500' : 'tw-stroke-green-700'"
            >
              <CheckMarkInCircle />
            </BaseIcon>
            <p class="tw-text-sm" :class="orgName.length < 5 ? 'tw-text-gray-500' : 'tw-text-gray-800'">
              {{ $t('organization_minimum_characters') }}
            </p>
          </div>
          <div class="tw-flex tw-items-start md:tw-items-center">
            <BaseIcon
              icon-name="information-outline"
              class="tw-fill-none tw-stroke-gray-800 tw-mr-1 tw-mt-[2px]"
              :viewbox-height="16"
              :viewbox-width="16"
            >
              <InformationOutline />
            </BaseIcon>
            <p class="tw-text-sm">
              {{ $t('organization_name_change_info') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import BaseIcon from '@/components/BaseIcon.vue'
import NetworkIcon from '@/components/icons/NetworkIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

import { changeOrganisationNameMutation } from './queries/index.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const { displayNotification } = useToastNotifications()
const { changeOrganisationName, changeOrganisationNameLoading } = changeOrganisationNameMutation()
const store = useStore()

const isEditing = ref(false)
const orgName = ref('')
const setEditMode = () => {
  isEditing.value = !isEditing.value
}

const trimOrgName = () => {
  orgName.value = orgName.value.trim()
}

const resetState = () => {
  if (isEditing.value) {
    setEditMode()
    setNewOrgName(currentOrganisation.value.name)
  } else {
    setEditMode()
  }
}
const setNewOrgName = name => {
  orgName.value = name
}

const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const currentOrganisation = computed(() =>
  store.state.session.organisations.find(item => item.id === selectedOrganisationId.value)
)

const saveNewOrgName = () => {
  if (orgName.value.length < 5) return
  changeOrganisationName(
    { organisationName: orgName.value },
    {
      onSuccess: data => {
        orgName.value = data.data.name
        setEditMode()
        displayNotification({
          message: 'organization_name_change_success',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        store.dispatch('session/getOrganisationsList')
      },
      onError: () => {
        orgName.value = currentOrganisation.value.name
        displayNotification({
          message: 'form.messages.error.unknown',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
        setEditMode
      },
    }
  )
}

onMounted(() => {
  orgName.value = currentOrganisation.value?.name
})
</script>
