<template>
  <section class="tw-py-6 md:tw-py-8" ref="appsCarousel">
    <div class="tw-flex tw-flex-row tw-w-vw tw-overflow-hidden carousel-container">
      <div class="inner carousel" ref="inner" :style="innerStyles">
        <div
          v-for="(app, index) in apps"
          :key="index"
          :id="app.id"
          class="tw-inline-block tw-items-center lg:tw-w-[980px] lg:tw-min-w-[980px] lg:tw-mx-[calc((100vw-980px)/2)] md:tw-w-[768px] md:tw-min-w-[768px] md:tw-mx-[calc((100vw-768px)/2)] tw-w-[330px] tw-min-w-[330px] tw-mx-[calc((100vw-330px)/2)] tw-m-auto"
        >
          <div class="tw-flex tw-whitespace-normal md:tw-gap-8 tw-gap-4 tw-items-center">
            <div
              class="tw-min-w-[80px] tw-w-auto md:tw-w-1/3 lg:tw-w-2/5 tw-order-1 md:tw-order-0 tw-flex tw-justify-end"
            >
              <img
                :src="app.image"
                :alt="$t(app.title)"
                class="tw-w-auto tw-h-[70px] md:tw-h-[136px]"
                :class="{ 'mirror-image': index === 0 }"
              />
            </div>
            <div class="tw-w-auto md:tw-w-2/3 lg:tw-w-3/5 tw-flex tw-flex-col tw-mr-3 tw-order-1">
              <p
                class="tw-text-[10px] sm:tw-text-lg lg:tw-text-2xl md:tw-max-w-[85%] lg:tw-w-full"
                v-html="$t(app.title)"
              />
              <p
                class="tw-text-[10px] sm:tw-text-lg lg:tw-text-2xl md:tw-max-w-[85%] lg:tw-w-full"
                v-html="$t(app.text)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-4 md:tw-mt-6">
      <button
        v-for="(_, index) in apps"
        :key="index"
        class="tw-w-[10px] tw-mx-[5px] tw-h-[10px] tw-rounded-full"
        :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import TouchEvent from '@/utils/touchEvents.js'

const HotelConnect = new URL('/src/assets/img/studio-plus-landing/hotel-connect.svg', import.meta.url)
const VisitorsProfile = new URL('/src/assets/img/studio-plus-landing/visitors-profile.svg', import.meta.url)

const apps = ref([
  {
    image: VisitorsProfile,
    title: 'studio_plus_new_feature_1_header',
    text: 'studio_plus_new_feature_1_text',
    id: 'app_1',
  },
  {
    image: HotelConnect,
    title: 'studio_plus_new_feature_2_header',
    text: 'studio_plus_new_feature_2_text',
    id: 'app_2',
  },
])

const appsCarousel = ref(null)
const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)

onMounted(async () => {
  await nextTick()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(0)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = apps.value.length

  step.value = `${innerWidth / totalCards}`
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElementId = window.document.querySelector('.carousel').firstElementChild.id
    const item = window.document.getElementById(firstElementId)
    item.parentNode.appendChild(item)
    if (firstElementId === 'app_1') current.value = 1
    if (firstElementId === 'app_2') current.value = 0
    resetTranslate()
    transitioning.value = false
  })
}

const moveLeft = () => {
  innerStyles.value = {
    transform: `translateX(-${step.value}px)`,
  }
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElementId = window.document.querySelector('.carousel').lastElementChild.id
    const item = window.document.getElementById(lastElementId)
    item.parentNode.prepend(item)

    if (lastElementId === 'app_1') current.value = 0
    if (lastElementId === 'app_2') current.value = 1

    resetTranslate()
    transitioning.value = false
  })
}

const moveRight = () => {
  innerStyles.value = {
    transform: `translateX(${step.value}px)`,
  }
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 8000)
}

const handleSwipe = event => {
  if (appsCarousel.value?.contains(event.target)) {
    if (!touchEvent.value) {
      return
    }
    touchEvent.value.setEndEvent(event)

    if (touchEvent.value.isSwipeRight()) {
      prev()
    } else if (touchEvent.value.isSwipeLeft()) {
      next()
    }
    touchEvent.value = null
  }
}
</script>
<style scoped>
.carousel {
  display: block;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}

.carousel img {
  @media (max-width: theme('screens.sm')) {
    transform: scaleX(-1);
  }
}
.mirror-image {
  transform: scaleX(-1);
}
</style>
