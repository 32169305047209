<template>
  <div class="tw-hidden tw-mt-10 md:tw-flex md:tw-flex-row md:tw-justify-between tw-gap-4">
    <GhostButton
      :disabled="backDisabled"
      size="big"
      class="tw-mb-3.5 md:tw-mb-0"
      :class="currentStep === 3 ? 'tw-w-1/2' : 'tw-w-full'"
      @click="$emit('onBack')"
      >{{ $t('setup_back') }}</GhostButton
    >
    <MainButton :disabled="nextDisabled" size="big" class="tw-w-full" @click="$emit('onNext')" v-if="currentStep !== 3">
      <span v-if="!isLoading">{{ $t('setup_next') }}</span>
      <span v-if="isLoading"> <RCInlineLoader color="#fff" /></span>
    </MainButton>
  </div>
  <div class="md:tw-hidden tw-flex tw-flex-col tw-gap-4">
    <MainButton @click="emit('onNext')" :disabled="nextDisabled" v-if="currentStep !== 3">{{
      $t('setup_next')
    }}</MainButton>
    <GhostButton @click="emit('onBack')" class="tw-text-gray-700 tw-font-bold" :disabled="backDisabled">{{
      $t('setup_back')
    }}</GhostButton>
  </div>
</template>
<script setup>
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

defineProps({
  nextDisabled: {
    type: Boolean,
    default: false,
  },
  backDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  currentStep: {
    type: Number,
    default: 1,
  },
})
const emit = defineEmits(['onNext', 'onBack'])
</script>
