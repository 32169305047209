<template>
  <div>
    <div class="tw-mb-4 border-b border-gray-200 dark:border-gray-700">
      <div class="tw-w-full tw-flex tw-mt-3 lg:tw-flex-nowrap tw-flex-wrap">
        <PerformanceCard
          v-for="(item, index) in kpiTabs"
          :id="`${item.key}-tab`"
          :key="item.key"
          :element-id="item.key"
          :label="item.label"
          :value="item.progression"
          :number="item.number"
          :index="index"
          :isSelected="selectedTab === item.key"
          role="tab"
          :data-tabs-target="`#${item.key}`"
          @onClick="changeTab(item.key)"
        />
      </div>
    </div>
    <div id="tabsContent">
      <PerformanceDashboardView
        v-if="selectedTab === 'visits'"
        tab-type="visits"
        :selectedDisplayType="selectedDisplayType"
        :selectedAggregation="selectedAggregation"
        @onDisplayTypeChange="changeDisplayType"
        @onSelectedAggregationChange="changeAggregation"
      >
        <ClickStats
          v-if="selectedDisplayType === 'chart' && visits"
          class="tw-p-6 tw-pt-0"
          :categories="categories"
          :campaignTypes="campaignTypes"
          :tickPositions="tickPositions"
          :prettyVisits="visits"
          :currency="currency"
        />
        <StatsTable v-if="selectedDisplayType === 'table'" kpi-highlight="clicks" />
      </PerformanceDashboardView>
      <PerformanceDashboardView
        v-if="selectedTab === 'bookings'"
        tab-type="bookings"
        :selectedDisplayType="selectedDisplayType"
        :selectedAggregation="selectedAggregation"
        @onDisplayTypeChange="changeDisplayType"
        @onSelectedAggregationChange="changeAggregation"
      >
        <BookingStats
          v-if="selectedDisplayType === 'chart' && bookings"
          class="tw-p-6 tw-pt-0"
          :categories="categories"
          :campaignTypes="campaignTypes"
          :tickPositions="tickPositions"
          :prettyBookings="bookings"
          :currency="currency"
        />
        <BookingTable v-if="selectedDisplayType === 'table'" />
      </PerformanceDashboardView>
      <PerformanceDashboardView
        v-if="selectedTab === 'revenue'"
        tab-type="revenue"
        :selectedDisplayType="selectedDisplayType"
        :selectedAggregation="selectedAggregation"
        @onDisplayTypeChange="changeDisplayType"
        @onSelectedAggregationChange="changeAggregation"
      >
        <RevenueStats
          v-if="selectedDisplayType === 'chart' && revenue"
          class="tw-p-6 tw-pt-0"
          :categories="categories"
          :campaignTypes="campaignTypes"
          :tickPositions="tickPositions"
          :prettyRevenue="revenue"
          :currency="currency"
        />
        <StatsTable v-if="selectedDisplayType === 'table'" kpi-highlight="revenue" />
      </PerformanceDashboardView>
      <PerformanceDashboardView
        v-if="selectedTab === 'commissionCost'"
        tab-type="commissionCost"
        :selectedDisplayType="selectedDisplayType"
        :selectedAggregation="selectedAggregation"
        @onDisplayTypeChange="changeDisplayType"
        @onSelectedAggregationChange="changeAggregation"
      >
        <CommissionStats
          v-if="selectedDisplayType === 'chart' && commissionCosts"
          class="tw-p-6 tw-pt-0"
          :categories="categories"
          :campaignTypes="campaignTypes"
          :tickPositions="tickPositions"
          :prettyCommissionCosts="commissionCosts"
          :currency="currency"
        />
        <StatsTable v-if="selectedDisplayType === 'table'" kpi-highlight="commissionCost" />
      </PerformanceDashboardView>
      <PerformanceDashboardView
        v-if="selectedTab === 'cpa'"
        tab-type="cpa"
        :selectedDisplayType="selectedDisplayType"
        :selectedAggregation="selectedAggregation"
        @onDisplayTypeChange="changeDisplayType"
        @onSelectedAggregationChange="changeAggregation"
      >
        <CpaStats
          v-if="selectedDisplayType === 'chart' && cpa"
          class="tw-p-6 tw-pt-0"
          :categories="categories"
          :campaignTypes="campaignTypes"
          :tickPositions="tickPositions"
          :prettyCpa="cpa"
          :currency="currency"
        />
        <StatsTable v-if="selectedDisplayType === 'table'" kpi-highlight="ecpa" />
      </PerformanceDashboardView>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import PerformanceCard from '@/components/rateConnect/performance/PerformanceCard.vue'
import ClickStats from '@/components/rateConnect/performance/ClickStats.vue'
import StatsTable from '@/components/rateConnect/performance/StatsTable.vue'
import BookingStats from '@/components/rateConnect/performance/BookingStats.vue'
import RevenueStats from '@/components/rateConnect/performance/RevenueStats.vue'
import CommissionStats from '@/components/rateConnect/performance/CommissionStats.vue'
import CpaStats from '@/components/rateConnect/performance/CpaStats.vue'
import PerformanceDashboardView from '@/components/rateConnect/performance/PerformanceDashboardView.vue'
import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance'
import BookingTable from '@/components/rateConnect/performance/BookingTable.vue'

const props = defineProps({
  currentCampaignType: {
    type: String,
    default: '',
  },
  prettyKpis: {
    type: Array,
    default: () => [],
  },
  currency: {
    type: String,
    default: '',
  },
  campaignTypes: {
    type: Array,
    default: () => [],
  },
  categories: {
    type: Array,
    default: () => [],
  },
  tickPositions: {
    type: Array,
    default: () => [],
  },
  bookings: {
    type: Object,
  },
  visits: {
    type: Object,
  },
  revenue: {
    type: Object,
  },
  cpa: {
    type: Object,
  },
  commissionCosts: {
    type: Object,
  },
})

const { selectedTab, selectedDisplayType, selectedAggregation } = useRateConnectPerformance()

const kpiTabs = computed(() => {
  const tabMap = ['visits', 'bookings', 'revenue', props.currentCampaignType === 'cpc' ? 'cpa' : 'commissionCost']
  return props.prettyKpis?.filter(item => tabMap.includes(item.key))
})
const changeTab = newTab => (selectedTab.value = newTab)
const changeDisplayType = newType => (selectedDisplayType.value = newType)
const changeAggregation = newAggregation => (selectedAggregation.value = newAggregation)
</script>
