<template>
  <div>
    <CompareFeaturesHero
      @upgradeSub="redirectToPayment"
      :currency="currency"
      :price="price"
      :isLoading="isPriceLoading"
    />
    <StudioPlusProductsHighlight />
    <div class="md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-px-4 tw-mx-auto tw-my-12">
      <h3 class="tw-text-2xl tw-font-bold tw-text-center tw-mb-12">
        {{ $t('plus_product_page_feature_comparison') }}
      </h3>
      <CompareFeaturesCards
        @upgradeSub="redirectToPayment"
        :currency="currency"
        :price="price"
        :isLoading="isPriceLoading"
      />
    </div>
    <StudioPlusBanner @upgradeSub="redirectToPayment" />
    <StudioPlusFaqSection />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useStudioPlusPrice } from '@/components/payment/queries'
import { selectedAccommodationId, useAccommodationsByOrgId, useUserDetails } from '@/layouts/queries'
import useRateConnect from '@/components/rateConnect/queries'

import appAnalytics from '@/utils/tracking'

import CompareFeaturesCards from '@/components/studioPlus/CompareFeaturesCards.vue'
import CompareFeaturesHero from '@/components/studioPlus/CompareFeaturesHero.vue'
import StudioPlusProductsHighlight from '@/components/studioPlus/StudioPlusProductsHighlight.vue'
import StudioPlusBanner from '@/components/studioPlus/StudioPlusBanner.vue'
import StudioPlusFaqSection from '@/components/studioPlus/StudioPlusFaqSection.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const currency = computed(() => store.state.currency)

const { studioPlusPrice, isLoading, isFetching } = useStudioPlusPrice({ selectedAccommodationId, currency })
const isPriceLoading = computed(() => isLoading.value || isFetching.value)
const price = computed(() => studioPlusPrice.value?.price ?? 0)

const language = computed(() => store.state.locale.language)
const userId = computed(() => store.state.session.user?.id)
const { userDetails } = useUserDetails(userId)
const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const { hotelierData } = useRateConnect(selectedAccommodationId)
const directRatesPartnerId = computed(() => hotelierData.value?.directRatesPartner)

const redirectToPayment = () => {
  router.push({ name: 'studio-plus-payment' })
  appAnalytics.track(appAnalytics.events.SP_COMPARE_FEATURES_CARD_CLICKED, {
    item_id: selectedAccommodationId.value,
    itemName: currentAccommodation?.value?.name,
    price: price.value,
    partnerId: directRatesPartnerId.value,
    language: language.value,
    studioUserId: userDetails?.value?.id,
    email: userDetails?.value?.email,
    firstName: userDetails?.value?.firstName,
    lastName: userDetails?.value?.lastName,
  })
}

onMounted(() => {
  const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
  appAnalytics.track(appAnalytics.events.SP_COMPARE_FEATURES_VISITED, {
    item_id: selectedAccommodationId.value,
    price: price.value,
    source: route.query ? route.query : '',
    traffic_source: trafficSource ?? '',
  })
})
</script>
